import { useGetIdentity, useLogout } from "@refinedev/core";
import nhost from "@src/lib/nhost";
import { useMainMenuDrawer } from "@src/store/main-menu-atoms";
import { Identity } from "@src/types";
import { Dropdown, MenuProps } from "antd";
import React, { FC, useState } from "react";
import Icons from "./Icons";
import ImageLoader from "./ImageLoader";
import PasswordResetModal from "./PasswordResetModal";

type Props = {
  className?: string;
};

const UserAvatar: FC<Props> = ({ className }) => {
  const { mutate: logout } = useLogout();
  const { data: userIdentity } = useGetIdentity<Identity>();
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const [{ visible }, actions] = useMainMenuDrawer();

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <p className="hidden md:flex font-bold">{userIdentity?.displayName}</p>
      ),
    },
    {
      key: "1",
      label: (
        <a
          className="flex items-center gap-4 capitalize"
          onClick={(e) => {
            e.preventDefault();
            logout();
            actions.set(false);
          }}
        >
          <span>
            <Icons.AiOutlineLogout />
          </span>
          logout
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          className="flex items-center gap-4 capitalize"
          onClick={(e) => {
            e.preventDefault();
            setPasswordResetModalOpen(true);
          }}
        >
          <span>
            <Icons.PasswordReset />
          </span>
          reset password
        </a>
      ),
    },
  ];

  return (
    <div className="flex w-full h-full">
      {passwordResetModalOpen && (
        <PasswordResetModal
          open={passwordResetModalOpen}
          setOpen={setPasswordResetModalOpen}
        />
      )}
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <div className="flex w-full h-full justify-center  items-center cursor-pointer  ">
          <div className="flex w-12 md:w-full h-full  ">
            <ImageLoader
              src={nhost.auth.getUser()?.avatarUrl}
              className="w-10 h-10 rounded-full "
              objectFit="object-cover"
            />
          </div>

          <p className="w-3/4 md:w-0  capitalize md:hidden">
            {userIdentity?.displayName}
          </p>
        </div>
      </Dropdown>
      {/* <div className="dropdown">
        <label
          tabIndex={0}
          className="flex w-full h-full btn btn-ghost justify-start gap-4 items-center"
        >
          <ImageLoader
            src={userIdentity?.profile?.avatarUrl}
            className="w-10 h-10 rounded-full"
            objectFit="object-cover"
          />

          {userIdentity?.profile?.username}
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li
            className="capitalize "
            onClick={() => {
              logout();
            }}
          >
            <a>
              <span>
                <Icons.AiOutlineLogout />
              </span>
              logout
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default UserAvatar;
