import nhost from "@src/lib/nhost";
import classNames from "classnames";
import React, {
  CSSProperties,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Icons from "./Icons";

type Props = {
  src?: string;
  storageId?: string;
  className?: string;
  objectFit?: "object-cover" | "object-fill" | "object-contain";
  name?: string;
  thumbnail?: boolean;
  tabIndex?: number;
  onFocus?: () => void;
  orgId?: string;
  style?: CSSProperties;
};

const ImageLoader: FC<Props> = ({
  src,
  className,
  objectFit,
  name,
  thumbnail = true,
  tabIndex,
  onFocus,
  orgId,
  storageId,
  style,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const imgParentRef = useRef<HTMLDivElement>();
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (imgParentRef.current) {
      setImgDimensions({
        width: imgParentRef.current.offsetWidth,
        height: imgParentRef.current.offsetHeight,
      });
    }
  }, [imgParentRef.current]);

  const calculatedSrc = useMemo(() => {
    if (!storageId && !src) {
      return "";
    } else if (src) {
      return src;
    } else
      return nhost.storage.getPublicUrl({
        fileId: storageId,
      });
  }, [storageId, src]);

  return (
    <div
      className={classNames(
        "flex w-full  h-full relative justify-center items-center "
      )}
      ref={imgParentRef}
    >
      {error || !calculatedSrc ? (
        <Icons.DefaultImage className="absolute opacity-50" />
      ) : (
        <img
          tabIndex={tabIndex}
          onFocus={onFocus}
          className={classNames(objectFit, "absolute", className)}
          src={calculatedSrc}
          onLoad={() => setLoading(false)}
          width={imgDimensions.width}
          height={imgDimensions.height}
          onError={(e) => {
            setError(true);
          }}
          style={style}
        />
      )}
    </div>
  );
};

export default ImageLoader;
