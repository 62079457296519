import { useCreate, useDelete, useUpdate } from "@refinedev/core";
import ImageLoader from "@src/components/ImageLoader";
import nhost from "@src/lib/nhost";
import { Image } from "@src/types";
import { resizeFile } from "@src/utils/image";
import { Upload } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

type Props = {
  image?: Image;
  className?: string;
  imageWidth?: number;
  imageHeight?: number;
  imageType: "avatar" | "org" | "service";
  onChange?: (...event: any[]) => void;
};

const Avatar = ({
  image,
  imageWidth = 400,
  imageHeight = 400,
  className,
  imageType,
  onChange,
}: Props) => {
  const [imageUrl, setImageUrl] = useState("");
  const { mutate: mutateCreate } = useCreate<Image>();
  const { mutate: mutateUpdate } = useUpdate<Image>();
  const { mutate: mutateDeleteStorage } = useDelete();

  //   const avatarImage = useList<Image>({
  //     resource: "image",
  //     meta: { fields: ["storageId", "id"] },
  //     filters: [{ field: "type", operator: "eq", value: "avatar" }],
  //   }).data?.data[0];

  useEffect(() => {
    if (image?.storageId) {
      const publicUrl = nhost.storage.getPublicUrl({
        fileId: image?.storageId,
      });

      setImageUrl(publicUrl);
    }
  }, [image]);

  const customUploadRequest = async (options: any) => {
    const formData = new FormData();

    formData.append("file", options.file);

    const file = await resizeFile(options.file, imageWidth, imageHeight);

    if (image?.id) {
      mutateDeleteStorage({
        resource: "storage",
        id: image.storageId,
        dataProviderName: "nhostObjects",
        meta: { operation: "deleteFile" },
      });
    }

    const result = await nhost.storage.upload({
      file,
    });

    mutateCreate(
      {
        resource: "image",
        values: {
          type: imageType,
          storageId: result.fileMetadata.id,
        },
      },
      {
        onSuccess: (image) => {
          const publicUrl = nhost.storage.getPublicUrl({
            fileId: image.data.storageId,
          });

          setImageUrl(publicUrl);
          onChange(image.data.id);
        },
      }
    );
  };

  return (
    <div className={classNames(className, " flex  items-center ")}>
      <Upload
        name="avatar"
        listType="picture-card"
        className={classNames("")}
        showUploadList={false}
        customRequest={customUploadRequest}
      >
        <ImageLoader
          src={imageUrl}
          className="w-full h-full p-1 justify-center items-center rounded-lg "
          objectFit="object-cover"
        />
      </Upload>
    </div>
  );
};

export default Avatar;
