//TODO handle axios error so that is doesnt write errors to console
//by defining custom axios client

import { AuthBindings } from "@refinedev/core";
import nhost from "@src/lib/nhost";

export type LoginFormValues = {
  email: string;
  password: string;
};

export type SocialLogin = "google" | "github" | "spotify" | "facebook";

const authProvider: AuthBindings = {
  login: async (input: LoginFormValues | SocialLogin) => {
    let error;

    if (input == "google")
      error = await nhost.auth.signIn({ provider: "google" });
    else if (input == "github")
      error = await nhost.auth.signIn({
        provider: "github",
      });
    else if (input == "spotify")
      error = await nhost.auth.signIn({
        provider: "spotify",
      });
    else if (input == "facebook")
      error = await nhost.auth.signIn({
        provider: "facebook",
      });
    else
      error = await nhost.auth.signIn({
        email: input.email,
        password: input.password,
      });

    if (error.error) {
      return {
        success: false,
        error: {
          message: error.error.message,
          name: "Login Error",
        },
      };
    }

    return {
      success: true,
      redirectTo: "/",
    };
  },
  logout: async () => {
    return nhost.auth
      .signOut()
      .then(() => {
        return {
          success: true,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: {
            message: e.message,
            name: "Logout Error",
          },
        };
      });
  },

  onError: async (error: any) => {
    if (error.status === 401) {
      nhost.auth.refreshSession();
    }

    return {};
  },

  check: async () => {
    nhost.auth.refreshSession();

    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
    if (isAuthenticated) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Not authenticated",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => {
    const user = nhost.auth.getUser();
    if (user) {
      return user.defaultRole;
    }

    return [];
  },
  getIdentity: async () => {
    const user = nhost.auth.getUser();
    if (user) {
      return {
        ...user,
        name: user.displayName,
        avatar: user.avatarUrl,
      };
    }

    return null;
  },
};

export default authProvider;
