import { useCan, useList } from "@refinedev/core";
import {
  dateAtom,
  dateRangeAtom,
  dayViewAtom,
  serviceIdAtom,
  serviceTypeAtom,
  staffIdAtom,
  tagsAtom,
  viewAtom,
} from "@src/store/calendar-atoms";
import { Service, Staff, Tag } from "@src/types";
import { Button, Divider, Switch } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";
import Icons from "../Icons";
import Label from "../Label";
import ServiceSelect from "../select/ServiceSelect";
import StaffSelect from "../select/StaffSelect";
import TagSelect from "../select/TagSelect";

type Props = { className: string; animeComplete: boolean };

const Toolbar = ({ className, animeComplete }: Props) => {
  const [staffId, setStaffId] = useAtom(staffIdAtom);
  const [date, setDate] = useAtom(dateAtom);
  const [view, setView] = useAtom(viewAtom);
  const [range] = useAtom(dateRangeAtom);
  const [dayView, setDayView] = useAtom(dayViewAtom);
  const [serviceId, setServiceId] = useAtom(serviceIdAtom);
  const [serviceType, setServiceType] = useAtom(serviceTypeAtom);
  const [tags, setTags] = useAtom(tagsAtom);

  const { data: canSelectStaff } = useCan({
    resource: "staff",
    action: "field",
    params: { field: "select" },
  });

  const { data: staffList, isFetching: staffIsFetching } = useList<Staff>({
    resource: "staff",
    meta: { fields: ["id", "authId"] },
    pagination: { pageSize: 100 },
    filters: [{ field: "active", operator: "eq", value: "True" }],
    queryOptions: {
      onSuccess: (res) => {
        setStaffId(res.data[0]?.id);
      },
      refetchOnMount: false,
    },
  });

  const { data: serviceList, isLoading: serviceIsLoading } = useList<Service>({
    resource: "service",
    meta: { fields: ["id", "name", "duration"] },
    filters: [{ field: "staffId", operator: "eq", value: staffId }],
    queryOptions: {
      enabled: !!staffId,
      onSuccess: (res) => {
        if (res.data.length > 0) setServiceId(res.data[0].id);
        else setServiceId("");
      },
      refetchOnMount: false,
    },
  });

  const tagsList = useList<Tag>({
    resource: "tag",
    meta: { fields: ["id", "name", "price"] },
  }).data?.data;

  const navigateNext = () => {
    let dateAsDayjs = dayjs(date);
    dateAsDayjs =
      view == "day" ? dateAsDayjs.add(1, "day") : dateAsDayjs.add(1, "month");

    setDate(dateAsDayjs.toDate());
  };

  const navigatePrev = () => {
    let dateAsDayjs = dayjs(date);
    dateAsDayjs =
      view == "day"
        ? dateAsDayjs.subtract(1, "day")
        : dateAsDayjs.subtract(1, "month");

    setDate(dateAsDayjs.toDate());
  };

  const navigationRender = () => {
    return (
      <div className="flex w-full items-center md:h-20">
        <Button
          type="text"
          size="large"
          className="text-xl"
          onClick={() => navigatePrev()}
        >
          <Icons.CalendarPrev size={30} />
        </Button>

        <div className="flex flex-col w-full items-center ">
          {view == "day" && (
            <p className="text-6xl">{dayjs(date).format("DD")}</p>
          )}
          <p className={classNames(view == "month" && "text-6xl")}>
            {dayjs(date).format("MMM")}
          </p>
        </div>

        <Button
          type="text"
          size="large"
          className="text-xl"
          onClick={() => navigateNext()}
        >
          <Icons.CalendarNext size={30} />
        </Button>
      </div>
    );
  };

  const staffSelectRender = () => {
    return (
      <div className={!canSelectStaff?.can ? "hidden" : "block"}>
        <Label label="staff" />
        <StaffSelect
          onChange={setStaffId}
          className={classNames("w-full")}
          value={staffId}
          items={staffList?.data}
          disabled={dayView == "booked" && view == "day"}
        />
      </div>
    );
  };

  const serviceSelectRender = () => {
    return (
      <div>
        <Label label="service" />
        <ServiceSelect
          onChange={setServiceId}
          className={classNames("w-full")}
          value={serviceId}
          items={serviceList?.data}
          disabled={dayView == "booked" && view == "day"}
        />
      </div>
    );

    // return (
    //   <div className="flex flex-col">
    //     <Label label="service" />
    //     <ServiceSelect
    //       onChange={setServiceId}
    //       className={classNames("w-full")}
    //       value={serviceId}
    //       staffId={staffId}
    //       disabled={dayView == "booked" && view == "day"}
    //     />
    //   </div>
    // );
  };

  return (
    <div
      className={classNames(
        "flex flex-col gap-2 px-2 py-4 h-full",
        className,
        "overflow-hidden"
      )}
    >
      <div
        className={classNames(
          "flex flex-col w-full justify-around items-center"
        )}
      >
        {navigationRender()}
      </div>

      <Divider dashed className="-my-1" />

      <div className="flex flex-col justify-center h-full gap-4 w-full px-4 max-w-md mt-6 md:mt-0 ">
        <div className="flex flex-col gap-1 md:gap-4 w-full ">
          {staffSelectRender()}
          {serviceSelectRender()}
        </div>

        <div
          className={classNames(
            "flex flex-col gap-5",
            view != "day" && "hidden"
          )}
        >
          {tagsList?.length > 0 && (
            <TagSelect items={tagsList} onChange={setTags} value={tags} />
          )}

          <div className="flex w-full flex-row gap-4 mt-4 items-center">
            <span className="font-light text-sm">Booked</span>

            <Switch
              className="w-10"
              onChange={() => {
                setTags([]);
                dayView == "booked"
                  ? setDayView("available")
                  : setDayView("booked");
              }}
              style={{ widows: 20 }}
              checked={dayView == "booked"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Toolbar };
