import { useDelete, useNavigation, useSelect } from "@refinedev/core";
import { OpeningHour } from "@src/types";
import { Event } from "@src/types/calendar";
import { enumerateHoursInBetween } from "@src/utils/date";
import { Badge, Button, Popconfirm } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { FC } from "react";
import Icons from "../Icons";
import { DayViewProps } from "./DayView";

const Presentation: FC<DayViewProps> = ({ ...props }) => {
  const { mutate } = useDelete();
  const { push } = useNavigation();

  const { queryResult: openingHours } = useSelect<OpeningHour>({
    resource: "opening_hour",
    metaData: { fields: ["from", "to", { translation: ["en"] }] },
  });

  const workDayHours = openingHours.data?.data.find(
    (wh) =>
      wh.translation.en === dayjs(props.date).format("dddd").toLocaleLowerCase()
  );

  const deleteConfirmed = (e: React.MouseEvent<HTMLElement>, id: string) => {
    mutate({ resource: "booking", id });
  };

  const deleteButtonRenderer = (id: string) => {
    return (
      <div className="relative z-0">
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this object?"
          onConfirm={(e) => deleteConfirmed(e, id)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
          placement="topRight"
          className="z-10"
        >
          <div className="flex absolute h-full w-full justify-end z-10 ">
            <Button
              type="ghost"
              className={classNames(
                "flex h-5 w-5 items-center justify-center bg-white rounded-full p-[2px]"
              )}
            >
              <Icons.AiOutlineDelete
                size={18}
                className="text-red-400 cursor-pointer"
              />
            </Button>
          </div>
        </Popconfirm>
      </div>
    );
  };

  const eventRenderer = ({ start, end, metaData }: Event, key: number) => {
    return (
      <div
        key={key}
        className="relative h-28 w-full border-dashed border rounded-md  cursor-pointer"
        onClick={() => push(`/booking/edit/${metaData.id}`)}
      >
        {/* {deleteButtonRenderer(metaData.id)} */}

        <div className="flex w-full justify-end">
          {metaData?.tags?.map((tag, idx) => (
            <Badge.Ribbon
              key={idx}
              text={tag}
              placement="end"
              color={tag == "vip" ? "gold" : "cyan"}
              className={classNames(
                "mt-16 text-sm",
                metaData?.tags[0] ?? "hidden"
              )}
              style={{ top: idx * 18 }}
            ></Badge.Ribbon>
          ))}
        </div>

        <div className="flex h-full w-full justify-center items-center">
          <div
            className="flex items-center justify-center w-4 h-full text-sm  rounded-l-md border-r font-light"
            style={{ backgroundColor: metaData?.color + "30" }}
          >
            <p className="whitespace-nowrap -rotate-90">
              {metaData?.staffProfile?.displayName}
            </p>
          </div>

          <div className="flex flex-col w-full place-items-center p-2">
            <p className="font-semibold">{`${start.format(
              "hh:mm"
            )} - ${end.format("hh:mm")}`}</p>
            <p className="overflow-hidden opacity-50">{`${metaData?.serviceName}`}</p>

            <p
              className="capitalize  cursor-pointer"
              onClick={() => push(`/booking/edit/${metaData.id}`)}
            >{`${metaData?.customer?.name}`}</p>

            {/* <Popover
              content={
                <p className="text-sm opacity-50 self-center">{`${formatMobile(
                  metaData?.customer?.mobile
                )}`}</p>
              }
              title={`${metaData?.customer?.name}`}
              trigger="click"
            >
              <p className="capitalize underline cursor-pointer">{`${metaData?.customer?.name}`}</p>
            </Popover> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full justify-between py-2">
      {enumerateHoursInBetween(dayjs(props.date).startOf("d"), {
        open: parseInt(dayjs(workDayHours?.from, "HH:mm:ss").format("HH")),
        close: parseInt(
          dayjs(workDayHours?.to, "HH:mm:ss").subtract(1, "h").format("HH")
        ),
      }).map((slot, key) => (
        <div key={key} className="border-b">
          <div className="flex flex-col h-full min-h-[4rem]">
            <p className="px-2 w-32 opacity-20 -translate-y-1">
              {slot.format("hh:mm a")}
            </p>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-2 gap-4 px-2  w-full h-full py-4 ">
              {props.events &&
                props.events
                  .filter(
                    (bs: Event) => bs.start.format("HH") == slot.format("HH")
                  )
                  .map((bs: Event, key: number) => eventRenderer(bs, key))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Presentation;
