import { useCan, useNavigation } from "@refinedev/core";
import { Button } from "antd";
import classNames from "classnames";
import { debounce } from "lodash";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import Icons from "./Icons";
import Input from "./input/Input";

type Props = {
  filterHandler: (val: string) => void | Dispatch<SetStateAction<string>>;
  componentName:
    | "staff"
    | "org"
    | "user"
    | "service"
    | "tag"
    | "customer"
    | "branch";
  className?: string;
  addButtonText?: string;
  addButtonAction?: () => void;
};

const Filterheader: FC<Props> = ({
  filterHandler,
  componentName,
  className,
  addButtonText,
  addButtonAction,
}) => {
  const { create } = useNavigation();
  const debouncedChangeHandler = useCallback(debounce(filterHandler, 500), []);
  const { data: canCreate } = useCan({
    resource: componentName,
    action: "create",
  });

  const [inputValue, setInputValue] = useState();

  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row items-center rounded",
        className
      )}
    >
      <div className="flex items-start w-full md:w-1/3 mb-2 md:mb-0  capitalize ">
        {componentName}
      </div>

      <div
        className={classNames(
          "flex w-full items-center justify-between gap-4",
          !canCreate?.can && "hidden"
        )}
      >
        <Input
          placeholder="search by name"
          onChange={(value) => {
            setInputValue(value);
            debouncedChangeHandler(value);
          }}
          value={inputValue}
          name="name"
          className="mb-1 w-full max-w-md"
        />

        <div className="flex justify-end md:w-1/3 h-full ">
          <Button
            type="ghost"
            className="flex items-center bg-secondary hover:bg-opacity-70 font-light gap-2"
            onClick={
              addButtonAction
                ? addButtonAction
                : () => {
                    create(componentName);
                  }
            }
            disabled={!canCreate?.can}
          >
            <Icons.AiOutlinePlus size={14} className="" />
            <p className="capitalize">{addButtonText ?? "create"}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filterheader;
