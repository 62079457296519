import { FormAction, useCan } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import StaffImageUpload from "@src/components/StaffImageUpload";
import { Staff } from "@src/types";
import { AuthUser, AuthUserReq } from "@src/types/auth-user";
import { Button, ColorPicker, Divider } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { FC, FormEvent, useCallback } from "react";
import { Controller } from "react-hook-form";
import Input from "../../input/BasicInput";
import NotificationCheckBox from "./NotificationCheckBox";

type FormType = "edit" | "create" | "show";

export type StaffForm = {
  staff: Staff;
  authUser: AuthUserReq;
};

type Props = {
  defaultValues?: Partial<Staff>;
  formType?: FormAction;
  authId?: string;
};

const Form: FC<Props> = ({ defaultValues, formType = "edit", authId }) => {
  const { data: canOrgId } = useCan({
    resource: "staff",
    action: "field",
    params: { field: "orgId" },
  });

  const {
    refineCore: { onFinish: staffOnFinish, queryResult: staffQueryResult },
    formState: { errors, dirtyFields },
    register,
    getValues: staffGetValues,
    watch: watchStaff,
    setValue,
    control: staffControll,
    handleSubmit,
  } = useForm<Staff>({
    defaultValues: _.omit(defaultValues, ["authUser", "orgId", "id"]),
    refineCoreProps: {
      meta: { fields: ["title", "color", "authId"] },
    },
  });

  const {
    refineCore: { onFinish: userOnFinish, setId },
    control: authUserControll,
    getValues: userGetValues,
    watch: watchAuthuser,
    setValue: setAuthUserValue,
  } = useForm<AuthUser>({
    defaultValues: defaultValues.authUser,
    refineCoreProps: {
      action: formType,
      id: authId,
      resource: "users",
      redirect: false,
      invalidates: ["resourceAll"],
      dataProviderName: "nhostObjects",
      meta: {
        fields: ["id", "displayName", "phoneNumber", "email", "avatarUrl"],
        operation: "user",
      },
      queryOptions: { enabled: !!authId },
    },
  });

  const tt = useCallback(() => {
    const borderColor = `border-[${watchStaff("color")}]`;

    return borderColor;
  }, [watchStaff("color")]);

  const onSubmitHandler = async (e: FormEvent) => {
    const staffValues: Partial<Staff> = staffGetValues();
    const { displayName, phoneNumber, avatarUrl } = userGetValues();

    await userOnFinish({ displayName, phoneNumber, avatarUrl }).catch((e) =>
      console.log("create user error", e)
    );

    await staffOnFinish(staffValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="flex flex-col gap-2 p-4 lg:max-w-lg "
    >
      <div className="flex w-full max-w-xs justify-center items-center ">
        <div className="flex w-24 h-24 rounded-full">
          <StaffImageUpload
            className={classNames("w-full h-full rounded-full p-[4px]")}
            imageWidth={200}
            imageHeight={400}
            staffId={watchStaff("authId")}
            avatarUrl={watchAuthuser("avatarUrl")}
            setValue={setAuthUserValue}
            style={{ background: watchStaff("color") }}
          />
        </div>
      </div>

      <div className="flex justify-center max-w-xs">
        <Controller
          name={"color"}
          render={({ field: { onChange, value } }) => (
            <ColorPicker
              value={value}
              onChange={(a, b) => onChange(b)}
              format="hex"
            >
              <Button
                className="flex items-center justify-center w-32 h-6 mt-4"
                type="dashed"
              >
                color
              </Button>
            </ColorPicker>
          )}
          control={staffControll}
        />
      </div>

      <Divider dashed />

      <Controller
        name={"displayName"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.surname}
            label={"name"}
            name="displayName"
            className="w-full max-w-xs"
            // disabled={formType == "show"}
          />
        )}
        control={authUserControll}
      />

      <Controller
        name={"email"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.username}
            label={"email"}
            name="email"
            className="w-full max-w-xs"
            // disabled={formType == "show"}
            disabled={true}
          />
        )}
        control={authUserControll}
      />

      <Controller
        name={"title"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.mobile}
            label={"title"}
            name="title"
            className="w-full max-w-xs"
          />
        )}
        control={staffControll}
      />

      <Controller
        name={"phoneNumber"}
        render={({ field: { onChange, value } }) => (
          <Input
            type="number"
            onChange={onChange}
            value={value}
            errors={errors.mobile}
            label={"mobile"}
            name="phoneNumber"
            className="w-full max-w-xs"
          />
        )}
        control={authUserControll}
      />

      {watchStaff("authId") && (
        <NotificationCheckBox staffId={watchStaff("authId")} />
      )}

      <Divider dashed />

      <Button
        htmlType="submit"
        type="ghost"
        className=" w-full max-w-xs text-white bg-primary"
      >
        <span>Save</span>
      </Button>
    </form>
  );
};

export default Form;
