import { Pagination } from "antd";
import React, { Dispatch } from "react";
import { LoadingScreen } from "./LoadingScreen";

type Props = {
  current: number;
  pageSize?: number;
  total?: number;
  setCurrent: Dispatch<React.SetStateAction<number>>;
};

const TableNav = ({ current, pageSize, setCurrent, total }: Props) => {
  if (total == undefined) return <LoadingScreen />;

  return (
    <Pagination
      simple
      current={current}
      total={total}
      pageSize={pageSize}
      onChange={(val) => setCurrent(val)}
      className="self-center mt-4"
    />
  );
};

export default TableNav;
