import { useCan, useMenu, useNavigation } from "@refinedev/core";
import { useMainMenuDrawer } from "@src/store/main-menu-atoms";
import { Button, Divider } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import ImageLoader from "./ImageLoader";
import UserAvatar from "./UserAvatar";

type Props = {};

const MainMenu: FC<Props> = ({}) => {
  const { menuItems } = useMenu();
  const { push } = useNavigation();
  const { pathname } = useLocation();
  const [{ visible }, actions] = useMainMenuDrawer();

  const activeMenuItem = menuItems
    .map((item) => item)
    .find((item) => {
      if (pathname == "/") return true;
      else if (pathname.includes(item.name)) return true;
    });

  return (
    <div className="flex flex-col pt-20 md:pt-6 px-6">
      <div className="flex h-16 w-16 self-center rounded-md">
        <ImageLoader
          src="/favicon.ico"
          className="w-full rounded-md"
          objectFit="object-cover"
        />
      </div>

      <Divider className="py-2" />

      <div className="flex flex-col shadow-none md:px-4">
        {menuItems.map(({ name, label, icon, route, key }, idx) => {
          const canList = useCan({ resource: name, action: "list" }).data?.can;

          if (!canList) return <div key={idx}></div>;

          return (
            <Button
              type="text"
              className="flex items-center h-12 "
              onClick={() => {
                push(route || "");
                actions.set(false);
              }}
              key={idx}
            >
              <div className="flex gap-4 items-center">
                <p
                  className={classNames(
                    "flex items-center justify-center rounded-lg shadow-xl w-8 h-8 ",
                    name == activeMenuItem.name &&
                      "border text-primary text-lg "
                  )}
                >
                  {icon}
                </p>
                <p
                  className={classNames(
                    "capitalize",
                    name == activeMenuItem.name && "font-bold "
                  )}
                >
                  {name}
                </p>
              </div>
            </Button>
          );
        })}
      </div>

      <Divider />

      <Button
        type="ghost"
        className={classNames("flex md:hidden w-full h-full")}
      >
        <UserAvatar className="" />
      </Button>
    </div>
  );
};

export default MainMenu;
