import { QueryClient } from "@tanstack/react-query";

const defaultOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
    mutations: {
      retry: 2,
    },
  },
};

const queryClient = new QueryClient(defaultOptions);

export const getClient = () => {
  return queryClient ? queryClient : new QueryClient(defaultOptions);
};
