import { useInfiniteList } from "@refinedev/core";
import Filterheader from "@src/components/Filterheader";
import { LoadingScreen } from "@src/components/LoadingScreen";
import Widget from "@src/components/Widget";
import Card from "@src/components/pages/branch/Card";
import { Adress } from "@src/types";
import classNames from "classnames";
import React, { useMemo, useState } from "react";

const List = () => {
  const [{}, setNameFilter] = useState("");

  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteList<Adress>({
      resource: "branch",
      metaData: {
        fields: [
          "id",
          "latLng",
          "description",
          { city: ["en"] },
          { area: ["en"] },
        ],
      },
      pagination: { pageSize: 4 },
    });

  const pagesData = useMemo(
    () => data?.pages.map((page) => page?.data).flat(),
    [data?.pages]
  );

  if (isLoading) return <LoadingScreen />;

  return (
    <Widget className="flex flex-col w-full justify-between p-4 overflow-scroll">
      <Filterheader
        className="mb-10"
        componentName="branch"
        filterHandler={setNameFilter}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:px-4 mb-4">
        {pagesData?.map((branch, idx) => (
          <div key={idx}>
            <Card adress={branch} />
          </div>
        ))}
      </div>

      <div className="btn-group self-center transition duration-100">
        <button
          className={classNames("btn btn-sm btn-ghost")}
          onClick={() => {
            fetchNextPage();
          }}
          disabled={!hasNextPage}
        >
          load more
        </button>
      </div>
    </Widget>
  );
};

export default List;
