import Form from "@src/components/pages/org/Form";
import useDrawer from "@src/hooks/useDrawer";
import React, { useEffect } from "react";

const Edit = (props: any) => {
  const handleClose = () => {
    drawer.close();
  };

  const drawer = useDrawer(<Form handleClose={handleClose} />, "right");

  useEffect(() => drawer.open(), []);

  return <div className="h-full w-full">{drawer.element}</div>;
};

export default Edit;
