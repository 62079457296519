import { useCreate, useDelete, useUpdate } from "@refinedev/core";
import nhost from "@src/lib/nhost";
import { Image } from "@src/types";
import { resizeFile } from "@src/utils/image";
import { Upload } from "antd";
import React, { CSSProperties, useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import ImageLoader from "./ImageLoader";

type Props = {
  className?: string;
  imageWidth?: number;
  imageHeight?: number;
  onChange?: (...event: any[]) => void;
  staffId: string;
  avatarUrl: string;
  setValue?: UseFormSetValue<FieldValues>;
  style?: CSSProperties;
};

function StaffImageUpload({
  imageWidth,
  imageHeight,
  className,
  avatarUrl,
  setValue,
  style,
}: Props) {
  const [imageUrl, setImageUrl] = useState("");
  const { mutate: mutateCreate } = useCreate<Image>();
  const { mutate: mutateUpdate } = useUpdate<Image>();
  const { mutate: mutateDeleteStorage } = useDelete();

  const customUploadRequest = async (options: any) => {
    const formData = new FormData();

    formData.append("file", options.file);

    const file = await resizeFile(options.file, imageWidth, imageHeight);

    nhost.storage
      .upload({
        file,
      })
      .then((res) => {
        const publicUrl = nhost.storage.getPublicUrl({
          fileId: res.fileMetadata.id,
        });

        setValue("avatarUrl", publicUrl);
      });
  };

  return (
    <Upload
      className="flex border-yellow-300"
      name="avatar"
      listType="picture-circle"
      showUploadList={false}
      customRequest={customUploadRequest}
    >
      <ImageLoader
        src={avatarUrl}
        className={className}
        objectFit="object-cover"
        style={style}
      />
    </Upload>
  );
}

export default StaffImageUpload;
