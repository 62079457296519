import classNames from "classnames";
import React, { FC, ReactElement, useCallback, useEffect } from "react";
import { useMainMenuDrawer } from "../store/main-menu-atoms";

type Props = { children?: ReactElement };

const MobileDrawer: FC<Props> = ({ children }) => {
  const [{ visible }, actions] = useMainMenuDrawer();

  const escFunction: any = useCallback((event: any) => {
    if (event.key === "Escape") {
      actions.set(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className={classNames(
        "flex h-full w-screen fixed z-30 transition-transform ease-in-out",
        !visible && "-translate-x-full",
        visible && "translate-x-0"
      )}
    >
      <div className={classNames("bg-white border h-full w-2/3 md:w-1/2")}>
        {children}
      </div>
      <div
        className={classNames(
          "  h-full w-1/3 md:w-1/2  transition-all ease-in-out cursor-pointer",
          !visible && "bg-gray-300/0",
          visible && "bg-black/30"
        )}
        onClick={() => actions.set(false)}
      ></div>
    </div>
  );
};

export default MobileDrawer;
