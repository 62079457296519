import { useDelete, useParsed } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import Icons from "@src/components/Icons";
import Label from "@src/components/Label";
import Input from "@src/components/input/Input";
import { Booking } from "@src/types";
import { successMessage } from "@src/utils/feedback-message";
import { Button, Divider, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import phoneFormatter from "phone-formatter";
import React from "react";
import { Controller } from "react-hook-form";

type Props = {
  handleClose?: () => void;
};

const EditForm = ({ handleClose }: Props) => {
  const { mutate } = useDelete();
  const { id } = useParsed();

  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
  } = useForm<Booking>({
    refineCoreProps: {
      redirect: false,
      meta: {
        fields: [
          "id",
          "note",
          "price",
          { customer: ["id", "name", "birthdate", "gender", "mobile"] },
        ],
      },
      onMutationSuccess: () => {
        successMessage("updated successfully!");

        handleClose();
      },
    },
  });

  const customerData = queryResult.data?.data?.customer;

  const handleDelete = (id: string) => {
    mutate(
      {
        id,
        resource: "booking",
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit(onFinish)}>
      <p className="flex w-2/3 gap-4 items-center justify-between mb-2">
        <span className="capitalize text-2xl">{customerData?.name}</span>
      </p>

      <p className="flex items-center gap-4 ">
        <span>
          {customerData?.gender == "male" ? (
            <Icons.Male size={24} />
          ) : (
            <Icons.Female size={24} />
          )}
        </span>
        <span className="flex text-xl items-center justify-center h-full ">
          {customerData?.birthdate && getAge(customerData?.birthdate)}
        </span>
      </p>

      <p className="opacity-60 ">
        {customerData?.mobile &&
          phoneFormatter.format(
            customerData?.mobile?.toString(),
            "0NNN NNN NNNN"
          )}
      </p>

      <Divider className="w-full" dashed />

      <Controller
        name={"price"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.username}
            label={"price"}
            name="price"
            className="w-full max-w-xs"
          />
        )}
        control={control}
      />

      {/* <div className="flex flex-col gap-2">
        <p>Note:</p>
        <TextArea rows={6} className="text-red" />
      </div> */}

      <div className="flex flex-col mt-4">
        <Label label="note" />
        <Controller
          name={"note"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextArea rows={8} onChange={onChange} value={value} />
          )}
        />
      </div>

      <Divider className="w-full" dashed />

      <div className="flex justify-between">
        <Button
          type="primary"
          className="flex items-center  justify-center gap-4 w-32"
          // disabled={!isValid}
          htmlType="submit"
        >
          <Icons.Save size={16} />
          <p>Save</p>
        </Button>

        <Popconfirm
          title="Delete"
          description="Are you sure to delete this object?"
          onConfirm={(e) => handleDelete(id as string)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
          placement="topRight"
          className="z-10"
        >
          <Button type="ghost" className="w-12 bg-danger text-white">
            <Icons.AiOutlineDelete />
          </Button>
        </Popconfirm>
      </div>
    </form>
  );
};

const getAge = (birthdate: Date) => {
  const diff = dayjs().diff(dayjs(birthdate), "years");
  return diff;
};

export default EditForm;
