import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineLoading,
  AiOutlineLogout,
  AiOutlinePlus,
  AiOutlineSave,
  AiOutlineTag,
} from "react-icons/ai";
import {
  BsBuilding,
  BsClock,
  BsGithub,
  BsImage,
  BsPeople,
  BsSpeedometer,
  BsSpotify,
  BsThreeDotsVertical,
  BsUpload,
} from "react-icons/bs";
import {
  FaFacebook,
  FaFemale,
  FaLanguage,
  FaMale,
  FaMobileAlt,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FiEdit, FiSettings } from "react-icons/fi";
import { GrMapLocation } from "react-icons/gr";
import { HiOutlineArrowDown, HiOutlineLocationMarker } from "react-icons/hi";
import { IoMdMenu } from "react-icons/io";
import {
  MdArrowBack,
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlineChangeHistory,
  MdOutlineEventAvailable,
  MdOutlineKeyboardArrowUp,
  MdOutlineManageAccounts,
} from "react-icons/md";
import {
  RiArrowGoBackFill,
  RiMoneyDollarCircleLine,
  RiServiceLine,
} from "react-icons/ri";
import { RxCalendar, RxFace } from "react-icons/rx";
import { TbLocationCog } from "react-icons/tb";
import { TiCloudStorageOutline } from "react-icons/ti";

const Icons = {
  AiOutlineEye,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlus,
  BsBuilding,
  AiOutlineLogout,
  HiOutlineLocationMarker,
  FaLanguage,
  FiSettings,
  IoMdMenu,
  BsPeople,
  BsSpeedometer,
  BsThreeDotsVertical,
  Edit: FiEdit,
  Close: AiOutlineClose,
  Save: AiOutlineSave,
  Service: RiServiceLine,
  User: RxFace,
  Calendar: RxCalendar,
  CalendarNext: MdNavigateNext,
  CalendarPrev: MdNavigateBefore,
  Back: MdArrowBack,
  Manage: MdOutlineManageAccounts,
  Loading: AiOutlineLoading,
  Storage: TiCloudStorageOutline,
  Phone: FaMobileAlt,
  CancelArrow: RiArrowGoBackFill,
  Tag: AiOutlineTag,
  ArrowDown: HiOutlineArrowDown,
  Upload: BsUpload,
  CheckArrow: AiOutlineCheck,
  BoxArrow: MdOutlineKeyboardArrowUp,
  PasswordReset: MdOutlineChangeHistory,
  DefaultImage: BsImage,
  Clock: BsClock,
  Google: FcGoogle,
  Github: BsGithub,
  Spotify: BsSpotify,
  Facebook: FaFacebook,
  MapLocation: GrMapLocation,
  BranchArrow: TbLocationCog,
  Male: FaMale,
  Female: FaFemale,
  Booking: MdOutlineEventAvailable,
  Money: RiMoneyDollarCircleLine,
};

export default Icons;
