import { useGetIdentity } from "@refinedev/core";
import { useMainMenuDrawer } from "@src/store/main-menu-atoms";
import { Identity } from "@src/types";
import { Button } from "antd";
import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import Icons from "./Icons";
import UserAvatar from "./UserAvatar";

type Props = {
  className: string;
};

const TopMenu = ({ className }: Props) => {
  const { pathname } = useLocation();
  const [{ visible }, actions] = useMainMenuDrawer();
  const { data: userIdentity } = useGetIdentity<Identity>();

  return (
    <div
      className={classNames(
        className,
        "fixed flex flex-row-reverse lg:flex-row p-2 md:p-0 w-full items-end md:items-center backdrop-blur-xl md:backdrop-blur-0 border-b md:border-b-0 bg-gray-300/30 md:bg-inherit rounded-none"
      )}
    >
      <div className="flex w-full h-full justify-end">
        <div className="h-full w-5/6 "></div>
        <div className={classNames("hidden md:flex h-full w-12")}>
          <UserAvatar className=" " />
        </div>
      </div>

      <div className="flex">
        <Button
          type="ghost"
          size="small"
          className="lg:hidden focus:outline-none rounded-full"
          onClick={() => actions.set(!visible)}
        >
          <Icons.IoMdMenu size={26} />
        </Button>
      </div>

      {/* <div className=" mb-1">
        <ThemeToggle />
      </div> */}
    </div>
  );
};

export default TopMenu;
