import { joiResolver } from "@hookform/resolvers/joi";
import { useLogout } from "@refinedev/core";
import nhost from "@src/lib/nhost";
import { Modal } from "antd";
import Joi from "joi";
import React, { Dispatch, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "./input/Input";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const schema = Joi.object({
  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "Invalid password, should include capital letters, symbols and nubmers. minimum of 6 characters",
    })
    .required(),
});

const PasswordResetModal = ({ open, setOpen }: Props) => {
  const {
    getValues,
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { password: "" },
    resolver: joiResolver(schema),
  });

  const { mutate: logout } = useLogout();

  const handleOk = () => {
    // trigger();

    // if (!isValid) return;

    console.log("test");

    nhost.auth
      .changePassword({ newPassword: "Test@12345" })
      .then(() => logout())
      .catch((e) => console.log(e));

    // updatePassword(
    //   { password: getValues("password") },
    //   {
    //     onSuccess: () => {
    //       message.success("password reset successful");
    //       setOpen(false);

    //       setTimeout(() => {
    //         logout();
    //       }, 1000);
    //     },
    //   }
    // );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title="Password Reset"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        className="w-full md:max-w-xs"
      >
        <form
          onSubmit={handleSubmit(handleOk)}
          className="flex items-center h-52"
        >
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                name="password"
                label="new password"
                value={value}
                onChange={onChange}
                className="w-full"
                errors={errors.password}
              />
            )}
          />
        </form>
      </Modal>
    </div>
  );
};

export default PasswordResetModal;
