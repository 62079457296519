import { useList, useNavigation } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import GenderRadio from "@src/components/GenderRadio";
import Label from "@src/components/Label";
import Input from "@src/components/input/Input";
import { Booking, Customer } from "@src/types";
import { Button, DatePicker, Divider } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Controller } from "react-hook-form";

type Props = { formType: "create" | "edit"; defaultValues: {} };

const Form = (props: Props) => {
  const { edit } = useNavigation();

  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
  } = useForm<Customer>({
    defaultValues: {},
    refineCoreProps: {
      meta: { fields: ["id", "name", "birthdate", "gender", "mobile"] },
    },
  });

  const { data, isLoading, isError } = useList<Booking>({
    resource: "booking",
    meta: {
      fields: [
        "id",
        "start",
        "note",
        { customer: ["id"] },
        { service: ["name"] },
      ],
    },
    filters: [
      {
        field: "customer.id",
        operator: "eq",
        value: queryResult.data?.data.id,
      },
    ],
    sorters: [{ field: "start", order: "desc" }],
  });

  const bookingRender = (booking: Booking, idx: number) => {
    return (
      <div
        key={idx}
        className="flex flex-col w-full border rounded-md p-2 min-h-[2rem]  cursor-pointer"
        onClick={() => edit("booking", booking.id)}
      >
        <p className="flex justify-between">
          <span> {dayjs(booking.start).format("YYYY-MM-DD")}</span>
          {booking.service?.name}
        </p>

        <Divider className="m-2" dashed />

        <p className="opacity-70 text-sm">{booking.note}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row w-full h-full p-4">
      <form
        className="flex flex-col w-full md:w-2/3  gap-4 "
        onSubmit={handleSubmit(onFinish)}
      >
        <Controller
          name={"name"}
          render={({ field: { onChange, value } }) => (
            <Input
              onChange={onChange}
              value={value}
              errors={errors.surname}
              label={"name"}
              name="displayName"
              className="w-full max-w-xs"
              // disabled={formType == "show"}
            />
          )}
          control={control}
        />

        <Controller
          name={"mobile"}
          render={({ field: { onChange, value } }) => (
            <Input
              onChange={onChange}
              value={value}
              errors={errors.surname}
              label={"mobile"}
              name="mobile"
              className="w-full max-w-xs"
              // disabled={formType == "show"}
            />
          )}
          control={control}
        />

        <Controller
          name={"gender"}
          render={({ field: { onChange, value } }) => (
            <GenderRadio onChange={onChange} value={value} />
          )}
          control={control}
        />

        <Controller
          name={"birthdate"}
          render={({ field: { onChange, value } }) => (
            <>
              <Label label="birthdate" className="-mb-2" />
              <DatePicker
                onChange={onChange}
                value={
                  value ? dayjs(value).endOf("date") : dayjs().endOf("date")
                }
                className="max-w-xs"
                format={"YYYY-MM-DD"}
              />
            </>
          )}
          control={control}
        />

        <Divider dashed />

        <Button
          htmlType="submit"
          type="ghost"
          className=" w-full max-w-xs text-white bg-primary"
        >
          <span>Save</span>
        </Button>
      </form>

      <Divider
        type="vertical"
        className=" h-full py-2 hidden md:block"
        dashed
      />

      <Divider className=" h-full py-4 block md:hidden" />

      <div className="flex flex-col md:w-1/3 h-full md:overflow-scroll">
        {/* <p className="text-xl mb-4 underline">Bookings</p> */}
        <div className="flex flex-col w-full gap-4">
          {data?.data.map((booking, idx) => {
            return bookingRender(booking, idx);
          })}
        </div>
      </div>
    </div>
  );
};

export default Form;
