import { Booking } from "@src/types";
import { Event } from "@src/types/calendar";
import dayjs, { Dayjs } from "dayjs";

const checkDateInRange = (
  bookedSlotStart: Dayjs,
  bookedSlotEnd: Dayjs,
  start: Dayjs,
  end: Dayjs
) => {
  if (
    start.isSame(bookedSlotStart) ||
    bookedSlotStart.isBetween(start, end) ||
    end.isSame(bookedSlotEnd) ||
    bookedSlotEnd.isBetween(start, end) ||
    (start.isSame(bookedSlotStart) && end.isSame(bookedSlotEnd))
  ) {
    return true;
  }

  return false;
};

export const getAvailableSlots = (
  start: Dayjs,
  end: Dayjs,
  duration: number,
  bookedSlots: Partial<Booking>[]
) => {
  let startClone = start.clone();
  const endClone = end.clone().endOf("minute");
  const result: Event[] = [];

  while (endClone.diff(startClone, "minute") >= duration) {
    const isBetween = bookedSlots.find((bookedSlot: Booking) => {
      return checkDateInRange(
        dayjs(bookedSlot.start),
        dayjs(bookedSlot.end),
        startClone,
        startClone.add(duration, "minute")
      );
    });

    if (isBetween) startClone = dayjs(isBetween.end);
    else {
      result.push({
        start: startClone,
        end: startClone.add(duration, "minute"),
      });
      startClone = startClone.add(duration, "minute");
    }
  }

  return result;
};
