import { AvailableSlots, Event } from "@src/types/calendar";
import { EagerBookingResponse, MomentEvent } from "@src/types/special";
import moment from "moment";

export const appointmentToEvent = (
  appointment: EagerBookingResponse[]
): Event[] => {
  // console.log(appointment, "fasdfasdf");
  return appointment?.map(({ staff, service, start, end }) => {
    return {
      title: `fix`,
      start: start,
      end: end,
    };
  });
};

export const appointmentToBookedEvent = ({
  start,
  staff,
  // userProfile,
  staffProfile,
  service,
  end,
  id,
  tags,
  customer,
}: EagerBookingResponse): Event => {
  return {
    start,
    end,
    metaData: {
      id,
      customer: customer,
      serviceName: service?.name,
      color: staff?.color,
      staff,
      staffProfile,
      tags: tags?.map((at) => at.tag?.name),
    },
  };
};

export const availableSlotToMonthlyView = (slots: AvailableSlots[]) => {
  return slots.map((slot) => {
    const tmpObj = {
      start: moment(slot.date.toDate()),
      end: moment(slot.date.toDate()),
      title: slot.info.total.toString(),
    };

    return tmpObj as MomentEvent;
  });
};

export const availableSlotsToMeelyView = (slots: AvailableSlots[]) => {
  return slots.map((slot) => slot.info.values).flat();
};
