import EditForm from "@src/components/pages/booking/EditForm";
import useDrawer from "@src/hooks/useDrawer";
import React, { useEffect } from "react";

type Props = {};

const Edit = (props: Props) => {
  const handleClose = () => {
    drawer.close();
  };

  useEffect(() => drawer.open(), []);

  const drawer = useDrawer(<EditForm handleClose={handleClose} />, "right");

  return <div className="h-full w-full">{drawer.element}</div>;
};

export default Edit;
