const toggleScrollBouncePrevention = (enable: boolean) => {
  if (enable) {
    document.addEventListener("touchmove", preventScroll, { passive: false });
  } else {
    document.removeEventListener("touchmove", preventScroll);
  }
};

const preventScroll = (event: any) => {
  event.preventDefault();
};

export const handleDropdownVisibleChange = (val: boolean) => {
  if (val) {
    document.body.style.overflowY = "hidden";
    toggleScrollBouncePrevention(true);
  } else {
    document.body.style.overflowY = "auto";
    toggleScrollBouncePrevention(false);
  }
};

export const handleDTimeSelectVisibleChange = (val: boolean) => {
  if (val) {
    document.body.style.overflowY = "scroll";
    document.body.style.touchAction = "none";
  } else {
    document.body.style.overflowY = "auto";
    document.body.style.touchAction = "auto";
  }
};
