import { useForm } from "@refinedev/react-hook-form";
import Input from "@src/components/input/BasicInput";
import { Org } from "@src/types";
import React from "react";
import { Controller } from "react-hook-form";

const Create = () => {
  const {
    refineCore: { onFinish, formLoading, queryResult },
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Org>({
    refineCoreProps: {
      metaData: { fields: ["id", "name"] },
      resource: "company",
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onFinish)}
      className="shadow-2xl p-4 lg:max-w-lg"
    >
      <Controller
        name={"name"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"name"}
            name="name"
            className=""
          />
        )}
        control={control}
      />

      <Controller
        name={"latlong"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"latlong"}
            name="latlong"
            className=""
          />
        )}
        control={control}
      />

      <Controller
        name={"adress"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"adress"}
            name="adress"
            className=""
          />
        )}
        control={control}
      />

      <button type="submit" className="btn btn-primary w-24 mt-6">
        {/* {formLoading} */}
        <span>Save</span>
      </button>
    </form>
  );
};

export default Create;
