import { useDelete } from "@refinedev/core";
import ColumnActions from "@src/components/ColumnActions";
import Icons from "@src/components/Icons";
import ImageLoader from "@src/components/ImageLoader";
import { Staff } from "@src/types";
import { getUuidLastPart } from "@src/utils/regex";
import { Divider } from "antd";
import phoneFormatter from "phone-formatter";
import React from "react";

type Props = {
  staffList: Staff[];
};

const CardList = ({ staffList }: Props) => {
  const { mutate } = useDelete();

  const handleDelete = (id: string, authId: string) => {
    mutate(
      {
        id: authId,
        resource: "",
        dataProviderName: "authUser",
      },
      {
        onSuccess: () => {
          mutate({ id, resource: "staff" });
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-2">
      {staffList?.map((staff, key) => (
        <div
          key={key}
          className="grid grid-cols-1 md:grid-cols-2 p-2 h-32 md:h-16 border md:border-0 md:border-b rounded-md md:rounded-none "
        >
          <div className="flex justify-around">
            <div className="flex w-2/3 md:w-1/2 gap-4 items-center">
              <div className="flex w-10 h-full">
                <ImageLoader
                  objectFit="object-cover"
                  className="flex w-10 h-10 rounded-full p-[2px] "
                  src={staff.authUser?.avatarUrl}
                  style={{
                    background: staff.color,
                  }}
                />
              </div>

              <div>
                <p className="text-lg capitalize">
                  {staff.authUser?.displayName}
                </p>

                <p className="uppercase text-xs opacity-50">
                  {getUuidLastPart(staff.id)}
                </p>
              </div>
            </div>
            <p className="flex items-center justify-end md:justify-center w-1/3 md:w-1/2 capitalize font-light opacity-80">
              {staff.title}
            </p>
          </div>

          <Divider className="my-2 md:hidden" dashed />

          <div className="flex justify-around">
            <div className="flex w-2/3 md:w-1/2 md:justify-center ">
              <p className="flex items-center gap-2 font-light">
                <span>
                  <Icons.Phone />
                </span>
                {phoneFormatter.format(
                  staff.authUser.phoneNumber || "00000000000",
                  "0NNN NNN NNNN"
                )}
              </p>
            </div>

            <div className="flex w-1/2 justify-center items-center">
              <ColumnActions
                resource="staff"
                id={staff.id}
                deleteCallback={() => handleDelete(staff.id, staff.authId)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardList;
