import {
  FormAction,
  useCreateMany,
  useDeleteMany,
  useList,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import Label from "@src/components/Label";
import { Image, OpeningHour, Org } from "@src/types";
import { Button, Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import React, { FC } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Input from "../../input/BasicInput";
import Avatar from "./Avatar";
import NewRow from "./NewRow";

type Props = {
  formType?: FormAction;
  orgId?: string;
  handleClose?: () => void;
};

type Test = {
  from: Dayjs;
  to: Dayjs;
};

const Form: FC<Props> = ({ formType, orgId, handleClose }) => {
  const defaultValues: Partial<Org> = {
    name: "",
    openingHours: [],
    avatarUrl: "",
  };

  const imageList = useList<Image>({
    resource: "image",
    meta: { fields: ["id", "type", "storageId"] },
  }).data?.data;

  const { mutate: createMany } = useCreateMany();
  const { mutate: deleteMany } = useDeleteMany();

  const {
    refineCore: { onFinish, queryResult },
    control,
    formState: { errors },
    getValues,
    setValue,
    register,
    watch,
  } = useForm<Org>({
    defaultValues,

    refineCoreProps: {
      onMutationSuccess: () => {
        handleClose();
      },
      redirect: false,
      metaData: {
        fields: [
          "name",
          // { openingHours: ["id", "from", "to", { translation: ["en"] }] },
        ],
      },
      queryOptions: {},
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "openingHours",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const openingHours = _.pick(getValues(), ["openingHours"])
      .openingHours as OpeningHour[];

    const openingHoursToBeDeleted = _.compact(
      openingHours.map((item: OpeningHour) => item.id)
    ) as string[];

    const nonDefaultOpeningHours = openingHours.filter((oh) => {
      return (
        !dayjs(oh.from, "HH:mm:ssZ").isSame(dayjs().startOf("day")) &&
        !dayjs(oh.to, "HH:mm:ssZ").isSame(dayjs().startOf("day"))
      );
    });

    deleteMany(
      { resource: "opening_hour", ids: openingHoursToBeDeleted },
      {
        onSuccess: () => {
          createMany(
            {
              resource: "opening_hour",
              values: nonDefaultOpeningHours,
            },
            {
              onSuccess: async () => {
                const orgInfo = _.omit(getValues(), [
                  "openingHours",
                  "avatarUrl",
                ]);

                await onFinish(orgInfo);
              },
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col w-full gap-4">
      <Controller
        name={"avatarUrl"}
        render={({ field: { onChange, value } }) => (
          <div className="flex w-full justify-center h-32 p-1">
            {/* <p className="text-sm mb-2">avatar</p> */}
            <Avatar
              className="flex  w-28 h-28 "
              imageType="avatar"
              image={imageList?.find((image) => image.type == "avatar")}
            />
          </div>
        )}
        control={control}
      />

      <Controller
        name={"name"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"name"}
            name="name"
            className="w-full capitalize"
          />
        )}
        control={control}
      />

      {/* <Controller
        name={"latlng"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label="location"
            name="latlng"
            className="w-full"
          />
        )}
        control={control}
      /> */}
      {/* 
      <div className="w-full">
        <Label label="opening hours" />
        <OpeningHours
          className="flex flex-col w-full gap-2 border rounded-md p-3"
          openingHours={queryResult.data?.data.openingHours}
          control={control}
          setValue={setValue}
        />
      </div> */}

      <Divider dashed className="my-1" />

      <div>
        <Label label="opening hours" />

        <NewRow
          openingHours={queryResult.data?.data.openingHours}
          control={control}
          fields={fields}
          setValue={setValue}
          watch={watch}
        />
      </div>

      <Divider dashed />

      <Button type="primary" htmlType="submit" className="w-24">
        <span>Save</span>
      </Button>
    </form>
  );
};

export default Form;
