import classNames from "classnames";
import React, { FC, ReactNode } from "react";

type Props = { children: ReactNode | ReactNode[]; className?: string };

const Widget: FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames("bg-white rounded-md", className)}>
      {children}
    </div>
  );
};

export default Widget;
