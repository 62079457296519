import { CrudFilters, useInfiniteList, useMany } from "@refinedev/core";
import {
  dateRangeAtom,
  loadingAtom,
  staffIdAtom,
} from "@src/store/calendar-atoms";
import { Booking } from "@src/types";
import { AuthUserRes } from "@src/types/auth-user";
import { EagerBookingResponse } from "@src/types/special";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import _ from "lodash";
import ms from "ms";
import { useEffect, useState } from "react";

const useBookingHook = (): [EagerBookingResponse[], boolean] | [] => {
  const [crudFilters, setCrudFilters] = useState<CrudFilters>();
  const [result, setResult] = useState<EagerBookingResponse[]>();

  // const [view, setView] = useAtom(viewAtom);
  // const [base] = useAtom(baseAtom);
  const [dateRange] = useAtom(dateRangeAtom);

  const [staffId] = useAtom(staffIdAtom);
  const [loading] = useAtom(loadingAtom);

  useEffect(() => {
    const filters: CrudFilters = [
      {
        field: "start",
        operator: "gt",
        value: dateRange.start?.format("YYYY-MM-DD HH:mm"),
      },
      {
        field: "end",
        operator: "lte",
        value: dateRange.end?.format("YYYY-MM-DD HH:mm"),
      },
    ];

    if (staffId) {
      filters.push({
        field: "staffId",
        operator: "eq",
        value: staffId,
      });
    }

    setCrudFilters(filters);
  }, [dateRange, staffId]);

  // useEffect(() => console.log(crudFilters), [crudFilters]);

  const {
    data: appointmentList,
    isLoading: appointmentLoading,
    isFetching: appointmentFetched,
    isSuccess: appSuccess,
  } = useInfiniteList<Booking>({
    resource: "booking",
    filters: crudFilters,
    meta: {
      fields: [
        "id",
        "start",
        "end",
        { staff: ["id", "color", "authId"] },
        { customer: ["id", "name", "mobile"] },
        { service: ["id", "duration", "name"] },
        { bookingTags: [{ tag: ["name", "price"] }] },
      ],
    },
    config: {
      pagination: { current: 1, pageSize: 100 },
    },
    queryOptions: { refetchInterval: ms(process.env.REFRESH_INTERVAL) },
  });

  const appointments = appointmentList?.pages.map((page) => page.data);

  const staffAuthIdsList = _.flatten(appointments).map(
    (app) => app.staff.authId
  );

  const { data: staffProfiles, isLoading: staffProfilesLoading } =
    useMany<AuthUserRes>({
      ids: staffAuthIdsList,
      resource: "users",
      dataProviderName: "nhostObjects",
      meta: { fields: ["id", "displayName"] },
      queryOptions: { enabled: !!staffAuthIdsList },
    });

  useEffect(() => {
    if (appointmentList && staffProfiles) {
      const results = _.flatten(appointments).map(
        ({ start, end, staff, service, customer, id, Tags: tags }) => {
          const staffProfile = _.find(staffProfiles?.data, {
            id: staff?.authId,
          });

          {
            return {
              id,
              start: dayjs(start),
              end: dayjs(end),
              staff,
              service,
              customer,
              staffProfile,
              tags,

              // translation: service?.translation,
            };
          }
        }
      );

      setResult(results);
    }
  }, [appointmentList, staffProfiles]);

  return [result, appointmentLoading || staffProfilesLoading];
};

export default useBookingHook;
