import classNames from "classnames";
import React from "react";

type Props = {
  label: string;
  className?: string;
};

const Label = ({ label, className }: Props) => {
  return (
    <label
      className={classNames(
        className,
        "mb-2 block font-light text-sm lowercase"
      )}
    >
      {label}
    </label>
  );
};

export default Label;
