import { useShow } from "@refinedev/core";
import ShowField from "@src/components/ShowField";
import { Org } from "@src/types";
import React from "react";

const Show = () => {
  const { queryResult } = useShow<Org>({
    meta: { fields: ["id", "name"] },
  });
  const { data } = queryResult;
  const record = data?.data;

  return (
    <div className="w-full h-full p-4 lg:max-w-xl shadow-2xl">
      <div className="flex gap-4 mb-4">
        <ShowField name="id" value={record?.id} className="w-1/3" />
        <ShowField name="name" value={record?.name} className="w-2/3" />
      </div>

      <ShowField name="uuid" value={record?.id} className="w-full" />
    </div>
  );
};

export default Show;
