import nhost from "@src/lib/nhost";
import { CanParams } from "@src/types/special";
import { newEnforcer } from "casbin.js";
import { adapter, model } from "./adapter";

const accessControlProvider = {
  can: async ({ action, params, resource }: CanParams) => {
    const role = nhost.auth.getUser().defaultRole;

    const enforcer = await newEnforcer(model, adapter);
    if (
      action === "delete" ||
      action === "edit" ||
      action === "create" ||
      action === "show"
    ) {
      const can = await enforcer.enforce(role, resource, action);

      return { can };
    }

    if (action === "field") {
      const can = await enforcer.enforce(
        role,
        `${resource}/${params?.field}`,
        action
      );

      return { can };
    }

    return Promise.resolve({
      can: await enforcer.enforce(role, resource, action),
    });
  },
};

export default accessControlProvider;
