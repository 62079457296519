import { useCan, useDelete, useList, useOne, useParsed } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import Label from "@src/components/Label";
import Input from "@src/components/input/Input";
import InputNumber from "@src/components/input/InputNumber";
import StaffSelect from "@src/components/select/StaffSelect";
import { Image, Service, Staff } from "@src/types";
import { Button, Divider } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import Icons from "../../Icons";
import Avatar from "../org/Avatar";

// type FormType = "edit" | "create" | "show";

type Props = { className: string; handleClose?: () => void };

const Form: FC<Props> = ({ className, handleClose }) => {
  const { data: canOrgId } = useCan({
    resource: "service",
    action: "field",
    params: { field: "orgId" },
  });
  const { mutate } = useDelete();
  const { id } = useParsed();

  const { data: staffList, isLoading: staffIsLoading } = useList<Staff>({
    resource: "staff",
    meta: { fields: ["id", "authId"] },
    pagination: { pageSize: 100 },
    filters: [{ field: "active", operator: "eq", value: "True" }],
  });

  const {
    refineCore: { onFinish, formLoading, queryResult, mutationResult },
    control,
    formState: { errors },
    getValues,
    watch,
  } = useForm<Service>({
    defaultValues: {
      name: "",
      duration: 0,
      price: 0,
      avatarUrl: "",
      staffId: "",
    },
    refineCoreProps: {
      redirect: false,
      onMutationSuccess: () => {
        handleClose();
      },
      onMutationError: (e) => {},
      metaData: {
        fields: ["name", "duration", "price", "avatarUrl", "staffId"],
      },
    },
  });

  const image = useOne<Image>({
    resource: "image",
    id: watch("avatarUrl"),
    meta: { fields: ["id", "storageId"] },
    queryOptions: { enabled: !!watch("avatarUrl") },
  }).data?.data;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await onFinish(getValues());
  };

  const handleDelete = (id: string) => {
    mutate(
      {
        id,
        resource: "service",
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  if (staffIsLoading) return;

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(
        "flex flex-col gap-4 w-full lg:max-w-lg",
        className
      )}
    >
      <Controller
        name={"avatarUrl"}
        render={({ field: { onChange, value } }) => (
          <Avatar
            className="flex w-full max-w-xs h-44 "
            imageType="service"
            image={image}
            onChange={onChange}
          />
        )}
        control={control}
      />

      <Controller
        name={"orgId"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"orgId"}
            name="orgId"
            className={classNames(
              "max-w-sm w-full",
              !canOrgId?.can && "hidden"
            )}
          />
        )}
        control={control}
      />

      <Controller
        name={"name"}
        render={({ field: { onChange, value } }) => (
          <Input
            onChange={onChange}
            value={value}
            errors={errors.name}
            label={"name"}
            name="name"
            size="large"
            className="max-w-xs w-full"
          />
        )}
        control={control}
      />

      <Controller
        name={"staffId"}
        render={({ field: { onChange, value } }) => (
          <div>
            <Label label="staff" />
            <StaffSelect
              onChange={onChange}
              value={value}
              className="max-w-xs w-full"
              size="large"
              items={staffList?.data}
            />
          </div>
        )}
        control={control}
      />

      <div className="flex max-w-xs gap-2">
        <Controller
          name={"duration"}
          render={({ field: { onChange, value } }) => (
            <InputNumber
              inputType="input"
              type="number"
              addonAfter="min"
              onChange={onChange}
              value={value}
              errors={errors.name}
              label={"duration"}
              name="duration"
              className="max-w-xs"
              step={5}
            />
          )}
          control={control}
        />

        <Controller
          name={"price"}
          render={({ field: { onChange, value } }) => (
            <InputNumber
              inputType="input"
              type="number"
              onChange={onChange}
              value={value}
              errors={errors.name}
              label={"price"}
              name="price"
              className="max-w-xs "
              addonAfter="IQD"
              // format="### ### ###"
              step={500}
            />
          )}
          control={control}
        />
      </div>

      <Divider />

      <div className="flex justify-between">
        <Button htmlType="submit" type="primary" className="w-32">
          <div className="flex w-full items-center justify-center  gap-2">
            <Icons.Save size={16} />
            <p>Save</p>
          </div>
        </Button>

        {/* <Popconfirm
          title="Delete"
          description="Are you sure to delete this object?"
          onConfirm={(e) => handleDelete(id as string)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
          placement="topRight"
          className="z-10"
        >
          <Button type="ghost" className="w-12 bg-danger text-white">
            <Icons.AiOutlineDelete />
          </Button>
        </Popconfirm> */}
      </div>
    </form>
  );
};

export default Form;
