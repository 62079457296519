// import { useLocation } from "@refinedev/react-router-v6";
import { useSelect } from "@refinedev/core";
import { dateAtom, dayViewAtom, viewAtom } from "@src/store/calendar-atoms";
import { OpeningHour } from "@src/types";
import { DateRange, DayView, Event } from "@src/types/calendar";
import classNames from "classnames";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useMemo } from "react";
import {
  Calendar,
  View,
  dayjsLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./BigCalendar.css";

const localizer = momentLocalizer(moment);
const localizer1 = dayjsLocalizer(dayjs);

type Props = {
  className: string;
  slotDuration?: number;
  views: {};
  view: View;
  dayView: DayView;
  events: Event[];
};

const BigCalendar: FC<Props> = ({
  className,
  views,
  view,
  dayView,
  events,
}) => {
  const [date, setDate] = useAtom(dateAtom);
  const [{}, setView] = useAtom(viewAtom);
  const [{}, setDayView] = useAtom(dayViewAtom);

  const getRange = (date: any, view: View): DateRange => {
    if (view == "month")
      return {
        start: dayjs(date).startOf("month"),
        end: dayjs(date).endOf("month"),
      };
    else if (view == "day")
      return {
        start: dayjs(date).startOf("day"),
        end: dayjs(date).endOf("day"),
      };
  };

  const { queryResult: openingHours } = useSelect<OpeningHour>({
    resource: "opening_hour",
    metaData: { fields: ["from", "to", { translation: ["en"] }] },
  });

  const workDays = useMemo(
    () => openingHours.data?.data.map((oh) => oh.translation?.en),
    [openingHours]
  );

  const isWorkDay = (date: Date) => {
    const formatedDate = dayjs(date).format("dddd").toLocaleLowerCase();
    const isWorkingDay = workDays?.find((wd) => wd === formatedDate);

    return isWorkingDay;
  };

  const DateCellWrapper = ({
    range,
    value,
    children,
  }: {
    range: Date[];
    value: Date;
    children: JSX.Element;
  }) => {
    const workDay = isWorkDay(value);

    return (
      <div
        className={classNames(
          "z-10 w-full h-full bg-none",
          workDay && "cursor-pointer "
        )}
        onClick={() => {
          onDrillDown({
            view: "day",
            date: value,
          });
        }}
      >
        {children}
      </div>
    );
  };

  const onDrillDown = useCallback(
    (props: any) => {
      setDate(props.date);
      setDayView("available");
      setView("day");
    },
    [dayView]
  );

  const CustomToolbar = (): any => {
    return null;
  };

  const formats = {
    dayFormat: (date: Date, culture: string, localizer: any) =>
      localizer.format(date, "ddd", culture), // Display abbreviated day names (e.g., Sat, Sun, Mon)
    dayRangeHeaderFormat: (
      { start, end }: any,
      culture: string,
      localizer: any
    ) => {
      const formattedStart = localizer.format(start, "MMM Do", culture); // Format start date
      const formattedEnd = localizer.format(end, "MMM Do", culture); // Format end date
      return `${formattedStart} - ${formattedEnd}`; // Display formatted date range
    },
  };

  return (
    <div className={classNames(className)}>
      <Calendar
        key={_.random()}
        localizer={localizer}
        events={events}
        views={views}
        view={view}
        date={date}
        defaultDate={new Date()}
        className="flex flex-col w-full"
        components={{
          dateCellWrapper: DateCellWrapper,
          toolbar: CustomToolbar,
          month: {
            dateHeader: ({ date, label }) => {
              const isToday =
                dayjs(date).format("YYMMDD") == dayjs().format("YYMMDD");

              return (
                <div
                  className={classNames(
                    !isWorkDay(date) && "opacity-40",
                    isToday && "font-extrabold text-xl text-red-400",
                    "flex items-center justify-center text-lg "
                  )}
                >
                  {label}
                </div>
              );
            },
            header: ({ date, localizer }) => (
              <div className="flex flex-col font-thin">
                {localizer.format(date, "dd")}
              </div>
            ),
            event: (event, a) => {
              return (
                <div className="flex justify-center h-full w-full lg:px-6">
                  <p
                    className={classNames(
                      "cursor-pointer select-none  px-2 w-full h-1",
                      parseInt(event?.title) <= 5 &&
                        "bg-gradient-to-r from-orange-100  to-orange-400",
                      parseInt(event?.title) > 5 &&
                        "bg-gradient-to-r from-green-50 to-green-400",
                      parseInt(event?.title) == 0 && "bg-none"
                    )}
                  ></p>
                </div>
              );
            },
          },
        }}
        onNavigate={() => {}}
        onView={() => {}}
        drilldownView={view}
        formats={formats}
      />
    </div>
  );
};

export default BigCalendar;
