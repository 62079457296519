import { useDelete } from "@refinedev/core";
import ColumnActions from "@src/components/ColumnActions";
import Icons from "@src/components/Icons";
import { Customer } from "@src/types";
import { Divider } from "antd";
import phoneFormatter from "phone-formatter";
import React from "react";

type Props = {
  customerList?: Customer[];
};

const CardList = ({ customerList }: Props) => {
  const { mutate } = useDelete();

  const handleDelete = (id: string) => {
    mutate({
      id,
      resource: "customer",
    });
  };

  console.log(customerList);

  return (
    <div className="flex flex-col w-full  gap-1">
      {customerList?.map((customer, key) => (
        <div
          key={key}
          className="grid grid-cols-1 md:grid-cols-2 justify-between h-32 md:h-16 items-center p-4 md:p-0 md:px-4 border md:border-0 md:border-b rounded-md md:rounded-none "
        >
          <div className="flex justify-start">
            <div className="flex w-3/4 md:w-1/2 capitalize ">
              {customer.name}
            </div>
            {customer.gender && (
              <div className="flex w-1/4 md:w-1/2 items-center justify-end md:justify-center ">
                {customer?.gender == "male" ? (
                  <Icons.Male size={24} />
                ) : (
                  <Icons.Female size={24} />
                )}
              </div>
            )}
          </div>

          <Divider className="md:hidden" dashed />

          <div className="flex w-full justify-end">
            <div className="flex w-full justify-end md:justify-center ">
              <div className="h-full w-2/3 md:w-1/2 ">
                <p className="flex items-center gap-2 font-light">
                  <span>
                    <Icons.Phone />
                  </span>
                  {customer.mobile &&
                    phoneFormatter.format(
                      customer.mobile?.toString(),
                      "0NNN NNN NNNN"
                    )}
                </p>
              </div>
              <div className="w-1/3 md:w-1/2">
                <ColumnActions
                  resource="customer"
                  id={customer.id}
                  deleteCallback={() => handleDelete(customer.id)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardList;
