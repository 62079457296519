import { useTable } from "@refinedev/core";
import Filterheader from "@src/components/Filterheader";
import CardList from "@src/components/pages/staff/CardList";
import TableNav from "@src/components/TableNav";
import Widget from "@src/components/Widget";
// import useStaffWithAuthUser from "@src/hooks/useStaffWithAuthUser";
import { Staff } from "@src/types";
import React, { useState } from "react";
import InviteStaff from "./InviteStaff";

const List = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    current,
    setCurrent,
    setFilters,
    pageSize,
    tableQueryResult: { data: staffList, isLoading },
    pageCount,
  } = useTable<Staff>({
    sorters: { initial: [{ field: "updatedAt", order: "desc" }] },
    meta: {
      fields: [
        "id",
        "color",
        "title",
        "authId",
        {
          authUser: ["displayName", "phoneNumber", "displayName", "avatarUrl"],
        },
      ],
    },
    pagination: {
      current: 1,
      pageSize: 8,
    },
    queryOptions: { cacheTime: 0 },
  });

  // const enrichedList = useAuthUser(staffList?.data);

  const filterHandler = (val: string) => {
    setCurrent(1);

    if (val === "") setFilters([], "replace");
    else
      setFilters([
        {
          field: "name",
          operator: "contains",
          value: `%${val}%`,
        },
      ]);
  };

  return (
    <Widget className="flex flex-col gap-4 justify-between w-full h-full p-4">
      <div className="h-16 ">
        <Filterheader
          componentName="staff"
          filterHandler={filterHandler}
          addButtonText="add"
          addButtonAction={() => setIsModalOpen(true)}
        />
      </div>

      <InviteStaff isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

      <div className="flex flex-col w-full h-full justify-center pt-4 md:pt-0 ">
        <CardList staffList={staffList?.data} />
      </div>

      <TableNav
        current={current}
        setCurrent={setCurrent}
        pageSize={pageSize}
        total={staffList?.total}
      />
    </Widget>
  );
};

export default List;
