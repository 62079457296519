import { dayViewAtom } from "@src/store/calendar-atoms";
import { DayView as DayViewType, Event } from "@src/types/calendar";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";
import { useSearchParams } from "react-router-dom";
import AvailaleDayViewPresentation from "./AvailaleDayViewPresentation";
import BookedDayViewPresentation from "./BookedDayViewPresentation";

export type DayViewProps = {
  events: Event[];
  date: Date;
};

export default function DayView({ events, date }: DayViewProps) {
  const [searchParams] = useSearchParams();

  const dayViewType = searchParams.get("dayView") as DayViewType;
  const [dayView] = useAtom(dayViewAtom);

  return dayView === "available" ? (
    <AvailaleDayViewPresentation events={events} date={date} />
  ) : (
    <BookedDayViewPresentation events={events} date={date} />
  );
}

DayView.range = (date: Date) => {
  return date;
};

DayView.navigate = (date: any, action: any, { localizer }: any) => {
  switch (action) {
    case "PREV":
      return dayjs(date).add(-1, "day").toDate();

    case "NEXT":
      return dayjs(date).add(1, "day").toDate();

    default:
      return date;
  }
};

DayView.view = (date: any, action: any, { localizer }: any) => {
  return date;
};

DayView.title = (date: Date, { localizer }: any) => {
  let month = dayjs(date).format("MMM");
  let start = dayjs(date).format("dddd");
  let dateFormat = dayjs(date).format("DD");

  return `${dateFormat} ${start}-${month}`;
};
