import {
  Authenticated,
  CanAccess,
  ErrorComponent,
  Refine,
} from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6";
import { ConfigProvider, theme } from "antd";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icons from "./components/Icons";
// import { withTiltle } from "./hoc/withTiltle";
import { NhostProvider } from "@nhost/react";
import dataProvider from "@refinedev/nhost";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout";
import { GqlClient } from "./lib/gql-client";
import nhost from "./lib/nhost";

import accessControlProvider from "./access-control/accessControlProvider";
import { getClient } from "./lib/react-query-client";
import Login from "./pages/auth/Login";
import CreateBooking from "./pages/booking/Create";
import ListBooking from "./pages/booking/List";
import Client from "./pages/client";
import EditCustomer from "./pages/customer/Edit";
import ListCustomer from "./pages/customer/List";
import ListDashboard from "./pages/dashboard/List";
import CreateOrg from "./pages/org/Create";
import EditOrg from "./pages/org/Edit";
import ListOrg from "./pages/org/List";
import ShowOrg from "./pages/org/Show";
import CreateService from "./pages/service/Create";
import EditService from "./pages/service/Edit";
import ListService from "./pages/service/List";
import CreateStaff from "./pages/staff/Create";
import EditStaff from "./pages/staff/Edit";
import ListStaff from "./pages/staff/List";
// import CreateTag from "./pages/tag/Create";
// import EditTag from "./pages/tag/Edit";
// import ListTag from "./pages/tag/List";
// import CreateUser from "./pages/user/Create";
// import EditUser from "./pages/user/Edit";
// import ListUser from "./pages/user/List";
import Signup from "./pages/auth/Signup";
import EditBooking from "./pages/booking/Edit";
import ListBranch from "./pages/branch/List";
import authProvider from "./providers/authProvider";
import nhostObjectsDataProvider from "./providers/nhostObjectsDataProvider";
import { darkModeAtom } from "./store/app";

const gqlClient = GqlClient.getInstance().client;
const queryClient = getClient();

const App: FC = () => {
  // const [notificationStatus] = useNotification();

  const [darkMode] = useAtom(darkModeAtom);

  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: "#f27222",
        },
        components: {},
      }}
    >
      <BrowserRouter>
        <NhostProvider nhost={nhost}>
          <Refine
            options={{
              disableTelemetry: true,
              reactQuery: {
                clientConfig: queryClient,
              },
            }}
            authProvider={authProvider}
            routerProvider={routerProvider}
            dataProvider={{
              default: dataProvider(nhost),
              nhostObjects: nhostObjectsDataProvider(nhost),
            }}
            accessControlProvider={accessControlProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: { icon: <Icons.BsSpeedometer />, label: "dashboard" },
              },
              {
                name: "booking",
                list: "/booking",
                create: "/booking/create",
                edit: "/booking/edit/:id",
                meta: { icon: <Icons.Calendar />, label: "booking" },
              },
              {
                name: "org",
                list: "/org",
                create: "/org/create",
                show: "/org/show/:id",
                edit: "/org/edit/:id",

                meta: { icon: <Icons.BsBuilding />, label: "organisation" },
              },
              {
                name: "branch",
                list: "/branch",
                create: "/branch/create",
                edit: "/branch/edit/:id",
                meta: { icon: <Icons.BranchArrow /> },
              },
              {
                name: "staff",
                list: "/staff",
                create: "/staff/create",
                edit: "/staff/edit/:id",
                meta: { icon: <Icons.BsPeople />, label: "staff" },
              },
              {
                name: "service",
                list: "/service",
                create: "/service/create",
                edit: "/service/edit/:id",
                meta: { icon: <Icons.Service /> },
              },
              {
                name: "customer",
                list: "/customer",
                edit: "/customer/edit/:id",
                meta: { icon: <Icons.User /> },
              },

              // {
              //   name: "user",
              //   list: "/user",
              //   create: "/user/create",
              //   edit: "/user/edit/:id",
              //   icon: <Icons.User />,
              // },
              // {
              //   name: "tag",
              //   list: "/tag",
              //   create: "/tag/create",
              //   edit: "/tag/edit/:id",
              //   icon: <Icons.Tag />,
              // },
              // {
              //   name: "storage",
              //   list: "/storage",
              //   create: "/storage/create",
              //   icon: <Icons.Storage />,
              // },
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated redirectOnFail="/login">
                    <Layout>
                      <CanAccess fallback={<div>Unauthorized!</div>}>
                        <Outlet />
                        <ToastContainer
                          transition={Flip}
                          className="mt-24 md:mt-0"
                        />
                      </CanAccess>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<ListDashboard />} />

                <Route path="booking">
                  <Route index element={<ListBooking />} />
                  <Route path="create" element={<CreateBooking />} />
                  <Route path="edit/:id" element={<EditBooking />} />
                </Route>

                <Route path="org">
                  <Route index element={<ListOrg />} />
                  <Route path="create" element={<CreateOrg />} />
                  <Route path="show/:id" element={<ShowOrg />} />
                  <Route path="edit/:id" element={<EditOrg />} />
                </Route>

                <Route path="branch">
                  <Route index element={<ListBranch />} />
                  <Route path="create" element={<CreateService />} />
                  <Route path="edit/:id" element={<EditService />} />
                </Route>

                <Route path="staff">
                  <Route index element={<ListStaff />} />
                  <Route path="create" element={<CreateStaff />} />
                  <Route path="edit/:id" element={<EditStaff />} />
                </Route>

                <Route path="service">
                  <Route index element={<ListService />} />
                  <Route path="create" element={<CreateService />} />
                  <Route path="edit/:id" element={<EditService />} />
                </Route>

                <Route path="customer">
                  <Route index element={<ListCustomer />} />
                  <Route path="edit/:id" element={<EditCustomer />} />
                </Route>

                {/* <Route path="openinghours">
                  <Route index element={<ListOpeningHours />} />
                  <Route path="edit/:id" element={<ListOpeningHours />} />
                </Route> */}
                {/*

                <Route path="user">
                  <Route index element={<ListUser />} />
                  <Route path="create" element={<CreateUser />} />
                  <Route path="edit/:id" element={<EditUser />} />
                </Route>

                <Route path="tag">
                  <Route index element={<ListTag />} />
                  <Route path="create" element={<CreateTag />} />

                  <Route path="edit/:id" element={<EditTag />} />
                </Route>
*/}
                {/* <Route path="storage">
                  <Route index element={<ListStorage />} />
                  <Route path="create" element={<></>} />
                </Route> */}
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />

              <Route path="/client/:id" index={true} element={<Client />} />

              <Route path="*" element={<ErrorComponent />} />
            </Routes>
          </Refine>
        </NhostProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
