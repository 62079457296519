import { CrudFilters, useList } from "@refinedev/core";
import { Customer } from "@src/types";
import { AutoComplete } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import Label from "./Label";

type Props = {
  disabled?: boolean;
  className?: string;
  setCustomer: UseFormSetValue<any>;
  size?: SizeType;
  orgId?: string;
};

const CustomerAutocomplete = ({
  disabled,
  className,
  setCustomer,
  size,
  orgId,
}: Props) => {
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState<{ value: string; id: string }[]>([]);
  const [queryFilter, setQueryFilters] = useState<CrudFilters>([
    { field: "name", operator: "contains", value: filter },
  ]);

  // useEffect(() => {
  //   if (orgId)
  //     setQueryFilters((prev) => [
  //       ...prev,
  //       { field: "orgId", operator: "eq", value: orgId },
  //     ]);
  // }, [orgId]);

  const customerList = useList<Customer>({
    resource: "customer",
    meta: { fields: ["id", "name", "mobile"] },
    filters: queryFilter,
  });

  useEffect(() => {
    setCustomer("customer", { id: "", mobile: "", name: filter });

    if (!filter) setQueryFilters([]);

    setQueryFilters(() => [
      { field: "name", operator: "contains", value: `%${filter}%` },
    ]);
  }, [filter]);

  const debouncedFilterHandler = useCallback(debounce(setFilter, 300), []);

  const handleSearch = (value: string) => {
    debouncedFilterHandler(value);
  };

  useEffect(() => {
    const existingOptions = customerList.data?.data.map((customer) => {
      return {
        value: customer.name,
        id: customer.id,
      };
    });

    setOptions(existingOptions);
  }, [customerList.data]);

  return (
    <div className="flex flex-col gap-2">
      <Label label="customer" />
      <AutoComplete
        size={size}
        className={className}
        popupClassName=""
        options={options}
        disabled={disabled}
        onSearch={handleSearch}
        placeholder="Choose customer"
        onSelect={(a: string, b: { value: string; id: string }) => {
          const customer = _.find(customerList.data?.data, { id: b.id });

          customer.mobile = customer.mobile
            ? String(customer.mobile).padStart(11, "0")
            : "";

          setCustomer("customer", customer, {
            shouldDirty: true,
          });
        }}
        onBlur={() =>
          options?.length == 0 &&
          setCustomer("customer", { id: "", mobile: "", name: filter })
        }
      />
    </div>
  );
};

export default CustomerAutocomplete;
