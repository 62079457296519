import Form from "@src/components/pages/service/Form";
import useDrawer from "@src/hooks/useDrawer";
import React, { useEffect } from "react";

const Edit = (props: any) => {
  useEffect(() => drawer.open(), []);

  const handleClose = () => {
    drawer.close();
  };

  const drawer = useDrawer(
    <Form className="md:pt-0" handleClose={handleClose} />,
    "right"
  );

  return <div className="w-full overflow-scroll">{drawer.element}</div>;
};

export default Edit;
