import { useNavigation, useSelect } from "@refinedev/core";
import { selectedSlotAtom } from "@src/store/calendar-atoms";
import { OpeningHour } from "@src/types";
import { Event } from "@src/types/calendar";
import { enumerateHoursInBetween } from "@src/utils/date";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React, { FC, useCallback, useMemo } from "react";
import { DayViewProps } from "./DayView";

const AvailaleDayViewPresentation: FC<DayViewProps> = ({ ...props }) => {
  const [{}, setSelectedSlot] = useAtom(selectedSlotAtom);
  const { create } = useNavigation();

  const { queryResult: openingHours } = useSelect<OpeningHour>({
    resource: "opening_hour",
    metaData: { fields: ["from", "to", { translation: ["en"] }] },
  });

  const workDayHours = openingHours.data?.data.find(
    (wh) =>
      wh.translation.en === dayjs(props.date).format("dddd").toLocaleLowerCase()
  );

  const handleEventOnClick = (event: Event) => {
    setSelectedSlot({
      start: event.start,
      end: event.end,
    });
    create("booking");
  };

  const workingHours = useMemo(() => {
    return enumerateHoursInBetween(dayjs(props.date).startOf("d"), {
      open: parseInt(dayjs(workDayHours?.from, "HH:mm:ss").format("HH")),
      close: parseInt(
        dayjs(workDayHours?.to, "HH:mm:ss").subtract(1, "h").format("HH")
      ),
    });
  }, [props.date]);

  const eventFormatter = useCallback(
    (event: Event, key: number, handleEventOnClick: (event: Event) => void) => {
      return (
        <div
          key={key}
          className="flex w-full lg:w-28 justify-center items-center h-10 py-1  shadow-sm rounded border cursor-pointer"
          onClick={() => handleEventOnClick(event)}
        >
          <p className="opacity-80">{event.start.format("hh:mm")}</p>
        </div>
      );
    },
    []
  );

  return (
    <div className="flex flex-col w-full justify-between py-2">
      {workingHours.map((slot, key) => (
        <div key={key} className="border-b ">
          <div className="flex h-full min-h-[4rem]">
            <p className="px-2 w-32 opacity-20 -translate-y-1">
              {slot.format("hh:mm a")}
            </p>
            <div className="grid grid-cols-4 items-center gap-2 px-4  w-full h-full py-2">
              {props.events &&
                props.events
                  .filter(
                    (as: Event) => as.start.format("HH") == slot.format("HH")
                  )
                  .map((as: Event, key: number) =>
                    eventFormatter(as, key, handleEventOnClick)
                  )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AvailaleDayViewPresentation;
