import { DateRange, DayView, ServiceType } from "@src/types/calendar";
import dayjs from "dayjs";
import { atom } from "jotai";
import { View } from "react-big-calendar";

export const viewAtom = atom<View>("month");

export const dayViewAtom = atom<DayView>("available");

export const staffIdAtom = atom("");

export const serviceIdAtom = atom("");

export const customerIdAtom = atom("");

export const serviceTypeAtom = atom<ServiceType>("staff");

export const tagsAtom = atom<string[]>([]);

export const loadingAtom = atom(false);

export const dateAtom = atom<Date>(new Date());

export const dateRangeAtom = atom<DateRange>((get) => {
  const dateAsDayjs = dayjs(get(dateAtom));
  const view = get(viewAtom);

  if (view == "month")
    return {
      start: dayjs(dateAsDayjs).startOf("month"),
      end: dayjs(dateAsDayjs).endOf("month"),
    };
  else if (view == "day")
    return {
      start: dayjs(dateAsDayjs).startOf("day"),
      end: dayjs(dateAsDayjs).endOf("day"),
    };
});

export const selectedSlotAtom = atom<DateRange>({
  start: dayjs().startOf("month"),
  end: dayjs().endOf("month"),
});
