import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import objectSupport from "dayjs/plugin/objectSupport";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

dayjs().tz("Asia/baghdad").format("YYYY:mm:dd HH:mm:ssZ");

export const timeToDto = (time: string) => {
  return dayjs(time, "HH:mm").tz("Asia/Baghdad").format("HH:mm:ssZ");
};

export const dateToTimeDto = (date: Dayjs) => {
  return date.format("HH:mm:ssZ");
};

export const timezoneFullToTwoDigits = (date: Dayjs) => {
  return date?.format("HH:mm:ssZ").slice(0, -3);
};
