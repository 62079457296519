import { yupResolver } from "@hookform/resolvers/yup";
import { useCreate, useSelect } from "@refinedev/core";
import Input from "@src/components/input/Input";
import { AuthUser } from "@src/types/auth-user";
import { Modal } from "antd";
import { SetStateAction } from "jotai";
import React, { Dispatch } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

type Props = { isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> };

const InviteStaff = ({ isOpen, setIsOpen }: Props) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const schema = yup
    .object({
      email: yup.string().matches(emailRegex),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const authUser = useSelect<AuthUser>({
    resource: "users",
    dataProviderName: "nhostObjects",
    meta: { fields: ["id", "email"] },
    filters: [{ field: "email", operator: "eq", value: watch("email") }],
    queryOptions: { enabled: isValid },
  });

  const { mutate } = useCreate();

  const handleOk = () => {
    mutate(
      {
        resource: "staff",
        values: { authId: authUser.queryResult.data?.data[0].id },
      },
      {
        onSuccess: () => setIsOpen(false),
        onError: (e) => {
          alert(e.message);
          return;
        },
      }
    );

    // setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(handleOk)}>
      <Modal
        title="Invite Staff"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="max-w-xs"
      >
        <Controller
          name="email"
          render={({ field: { value, onChange } }) => (
            <Input
              label="email"
              value={value}
              onChange={onChange}
              name="email"
            />
          )}
          control={control}
        />
        <p>{errors.email && "error"}</p>
      </Modal>
    </form>
  );
};

export default InviteStaff;
