import Form from "@src/components/pages/staff/Form";
import Widget from "@src/components/Widget";
import { Staff } from "@src/types";
import React from "react";

const Create = () => {
  const defaultValues: Staff = {
    id: "",
    authId: "",
    color: "",
    orgId: "",
    title: "",
    authUser: {
      roles: [{ role: "org.staff" }],
      displayName: "",
      avatarUrl: "",
      password: "Reset@123",
      defaultRole: "org.staff",
    },
  };

  return (
    <Widget className="w-full overflow-scroll">
      <Form formType="create" defaultValues={defaultValues} />
    </Widget>
  );
};

export default Create;
