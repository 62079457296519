import { useNavigation } from "@refinedev/core";
import Icons from "@src/components/Icons";
import Widget from "@src/components/Widget";
import { Divider } from "antd";
import classNames from "classnames";
import React, { ReactNode, useState } from "react";

type Props = { children: ReactNode | ReactNode[]; className?: string };

type ReturnObj = {
  element: JSX.Element;
  open: () => void;
  close: () => void;
};

type Children = ReactNode | ReactNode;
type Position = "left" | "right";

const useDrawer = (
  children: Children,
  position: Position = "left",
  goBack: boolean = true
): ReturnObj => {
  const timeout = 200;
  const [isOpen, setIsOpen] = useState(false);
  const [transition, setTransition] = useState(false);
  const { goBack: navGoBack } = useNavigation();

  const open = () => {
    setIsOpen(true);

    setTimeout(() => {
      setTransition(true);
    }, 10);
  };

  const close = () => {
    setTransition(false);
    setTimeout(() => {
      setIsOpen(false);
      navGoBack();
    }, timeout);
  };

  const element = isOpen && (
    <>
      <div
        className={classNames(
          "absolute md:fixed md:top-0 left-0 z-10 md:z-20 flex flex-row-reverse w-full h-full md:max-h-screen backdrop-blur-sm px-1 md:overflow-scroll",
          position == "right" ? " flex-row-reverse" : "flex-row"
        )}
      >
        <div
          className={classNames(
            "flex flex-col w-full md:w-1/2 lg:w-1/4  transform transition-transform duration-200 ease-in-out",
            transition
              ? "translate-x-0"
              : position == "left"
              ? "-translate-x-full"
              : "translate-x-full"
          )}
        >
          <Widget className="flex flex-col w-full h-full p-4">
            <div className="h-20">
              <Icons.Close
                className=" border w-8 h-8 p-2 cursor-pointer"
                onClick={close}
              />

              <Divider dashed />
            </div>

            <div className="flex w-full h-full">{children}</div>
          </Widget>
        </div>
      </div>
    </>
  );

  return { element, open, close };
};

export default useDrawer;
