import { joiResolver } from "@hookform/resolvers/joi";
import { useCreate, useList } from "@refinedev/core";
import { Booking, Customer, Service, Staff } from "@src/types";
import { errorMessage, successMessage } from "@src/utils/feedback-message";
import { Modal as AntdModal, Button, Divider } from "antd";
import classNames from "classnames";
import { Dayjs } from "dayjs";
import Joi from "joi";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, UseFormReset, useForm } from "react-hook-form";
import ImageLoader from "../ImageLoader";
import Input from "../input/Input";

type Props = {
  id: string;
  selectedSlot: Dayjs;
  selectedService: Service;
  selectedStaff: Staff;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  resetParent: UseFormReset<FormType>;
};

const schema = Joi.object({
  mobile: Joi.string()
    .pattern(/^\d{11}$/)
    .required()
    .messages({
      "string.pattern.base": "invalid mobile number",
    }),

  name: Joi.string().min(3).required(),
}).unknown(true);

type FormType = {
  service: Service;
  staff: Staff;
  date: Dayjs;
  orgId: string;
  mobile: string;
  customerId: string;
  name: string;
};

const Modal = ({
  id,
  selectedSlot,
  selectedService,
  selectedStaff,
  open,
  setOpen,
  resetParent,
}: Props) => {
  const { mutate: createBooking } = useCreate<Booking>();
  const { mutate: createCustomer } = useCreate<Customer>();

  const {
    reset,
    watch,
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
  } = useForm<FormType>({
    defaultValues: {
      mobile: "",
      customerId: "",
      name: "",
    },
    resolver: joiResolver(schema),
  });

  const { data: customerData, isLoading: customerIsLoading } =
    useList<Customer>({
      resource: "customer",
      meta: { fields: ["id","orgId","name", "mobile"] },
      queryOptions: { enabled: watch("mobile").length == 11 && !errors.mobile },
      filters: [
        { field: "mobile", operator: "eq", value: watch("mobile") },
        { field: "orgId", operator: "eq", value: id },
      ],
    });


  useEffect(() => {
    if (watch("mobile").length == 11 && customerData?.data[0]) {
      trigger();
      setValue("name", customerData?.data[0].name, { shouldValidate: true });
      setValue("customerId", customerData?.data[0].id);
    } else {
      setValue("name", "");
      setValue("customerId", "");
    }
  }, [customerData?.data, watch("mobile")]);



  const handleModalOk = async () => {


    const customer = new Promise((resolve, reject) => {
      const customerId = getValues("customerId");

      if (getValues("customerId")) resolve(customerId);

      else
        createCustomer(
          {
            resource: "customer",
            values: {
              name: getValues("name"),
              mobile: getValues("mobile"),
              orgId: id,
            },
          },
          {
            onSuccess: (resp) => resolve(resp.data.id),
            onError: () => reject("customer creation failed"),
          }
        );
    });


    await customer.then((customerId) =>
      createBooking(
        {
          resource: "booking",
          values: {
            start: selectedSlot,
            end: selectedSlot.add(selectedService?.duration, "minute"),
            customerId: customerId,
            staffId: selectedStaff.id,
            serviceId: selectedService.id,
          },
        },
        {
          onSuccess: () => {
            successMessage("booking successfull");

            reset();
            resetParent();
          },
          onError: () => errorMessage("booking failed"),
        }
      )
    );

    setOpen(false);
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  return (
    <AntdModal
      title=""
      open={open}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      closable={false}
      className="w-full max-w-xs md:max-w-sm"
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit(handleModalOk)}
        >
          Book
        </Button>,
      ]}
    >
      <div className="flex flex-col w-full h-72">
        <div className="flex h1/3 w-full ">
          <div className="flex flex-col gap-4 items-center justify-center p-4 w-1/3 h-full ">
            <ImageLoader
              className="w-20 h-20 rounded-full"
              src={selectedStaff?.authUser?.avatarUrl}
              objectFit="object-cover"
            />
          </div>
          <div className="flex flex-col gap-4 items-center justify-center w-2/3 h-full ">
            {selectedService && (
              <div className="flex flex-col gap-2 opacity-80">
                <div className="flex gap-2 text-md">
                  <p className="capitalize">{`${selectedService.name} (${selectedService.duration}min)`}</p>
                </div>
                <div className="flex flex-col items-center justify-end">
                  <p className="font-semibold">
                    {selectedSlot?.format("MMM DD")}
                  </p>
                  <p>{selectedSlot?.format("hh:mm a")}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Divider dashed className="my-2" />

        <div className="flex flex-col h-full py-8 gap-2">
          <Controller
            name={"mobile"}
            render={({ field: { onChange, value } }) => (
              <Input
                name="mobile"
                onChange={onChange}
                value={value}
                className="w-full max-w-xs"
                placeholder="mobile"
                errors={errors.mobile}
                // onBlur={() => trigger("mobile")}
              />
            )}
            control={control}
          />

          <Controller
            name={"name"}
            render={({ field: { onChange, value } }) => (
              <Input
                name="name"
                onChange={onChange}
                value={value}
                className={classNames("w-full max-w-xs")}
                placeholder="name"
                errors={errors.name}
                hidden={!!customerData?.data[0]}
              />
            )}
            control={control}
          />
        </div>
      </div>
    </AntdModal>
  );
};

export default Modal;
