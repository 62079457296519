import { useCan, useNavigation } from "@refinedev/core";
import { Popconfirm } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import Icons from "./Icons";

type Props = {
  resource: string;
  id: string;
  deleteCallback?: () => void;
};

const ColumnActions: FC<Props> = ({ resource, id, deleteCallback }) => {
  const { show, edit } = useNavigation();

  const canDelete = useCan({ resource, action: "delete" }).data?.can;
  const canEdit = useCan({ resource, action: "edit" }).data?.can;
  const canShow = useCan({ resource, action: "show" }).data?.can;

  const deleteConfirmedCallback = (
    e: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    deleteCallback();
  };

  return (
    <div className="flex w-full justify-end gap-2 font-thin opacity-80 ">
      <button
        className={classNames(
          !canEdit && "hidden",
          "btn btn-xs btn-ghost md:w-8 md:h-8 w-6 h-6 btn-circle"
        )}
        onClick={() => edit(resource, id)}
        disabled={!canEdit}
      >
        <Icons.AiOutlineEdit size={16} />
      </button>

      <Popconfirm
        title="Delete"
        description="Are you sure to delete this object?"
        onConfirm={(e) => deleteConfirmedCallback(e, id)}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
        placement="topRight"
        className="z-10"
      >
        <button
          className="btn btn-xs btn-ghost md:w-8 md:h-8 w-6 h-6 btn-circle"
          disabled={!canDelete}
        >
          <Icons.AiOutlineDelete size={16} />
        </button>
      </Popconfirm>
    </div>
  );
};

export default ColumnActions;
