export const lsThemeVaraibleName = "darkMode";

export const darkMode = () => {
  const darkTheme = localStorage.getItem("darkMode");
  return JSON.parse(darkTheme);
};

export const setHtmlDarkMode = (darkMode: boolean) => {
  const htmlTag = document.getElementById("html");

  // if (darkMode) {
  //   htmlTag.setAttribute("data-theme", "dark");
  //   document.documentElement.classList.toggle("dark");
  // } else {
  //   htmlTag.setAttribute("data-theme", "light");
  //   document.documentElement.classList.toggle("light");
  // }
};
