import React from "react";
import Icons from "./Icons";

type Props = {};

const LoadingScreen = (props: Props) => {
  return (
    <div className="fixed z-50 flex items-center justify-center top-0 left-0 w-full h-full bg-white/20 ">
      <Icons.Loading className="animate-spin" size={50} />
    </div>
  );
};

export { LoadingScreen };
