import { useOne, useSelect } from "@refinedev/core";
import Icons from "@src/components/Icons";
import Widget from "@src/components/Widget";
import BigCalendar from "@src/components/calendar/BigCalendar";
import DayViewComponent from "@src/components/calendar/DayView";
import { Toolbar } from "@src/components/calendar/Toolbar";
import useBookingHook from "@src/hooks/useBooking";

import {
  dateRangeAtom,
  dayViewAtom,
  loadingAtom,
  serviceIdAtom,
  staffIdAtom,
  tagsAtom,
  viewAtom,
} from "@src/store/calendar-atoms";
import { OpeningHour, Service } from "@src/types";
import { AvailableSlots, Event } from "@src/types/calendar";
import { enumerateDatesInBetween, setDateOnTimeString } from "@src/utils/date";
import { getAvailableSlots } from "@src/utils/event";
import {
  appointmentToBookedEvent,
  availableSlotToMonthlyView,
  availableSlotsToMeelyView,
} from "@src/utils/mapper";
import { Button } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const List = () => {
  const [appointmentList, appointmentLoading] = useBookingHook();
  const [view, setView] = useAtom(viewAtom);

  const [events, setEvents] = useState([]);
  const [animeComplete, setAnimeComplete] = useState(true);

  const [dateRange] = useAtom(dateRangeAtom);
  const [serviceId] = useAtom(serviceIdAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [dayView, setDayView] = useAtom(dayViewAtom);
  const [staffId] = useAtom(staffIdAtom);
  const [tags] = useAtom(tagsAtom);

  const service = useOne<Service>({
    resource: "service",
    id: serviceId,
    queryOptions: { enabled: !!serviceId },
    meta: { fields: ["id", "duration"] },
  }).data?.data;

  const openingHours = useSelect<OpeningHour>({
    resource: "opening_hour",
    metaData: { fields: ["from", "to", { translation: ["en"] }] },
  }).queryResult?.data?.data;

  useEffect(() => {
    setLoading(true);
  }, [dateRange, dayView]);

  useEffect(() => {
    if (loading) setTimeout(() => setLoading(false), 100);
  }, [loading]);

  const bookedSlotsMemo: Event[] = useMemo(() => {
    if (appointmentList) {
      let list = appointmentList;

      if (tags.length > 0 && dayView == "booked") {
        list = list.filter((appointment) => {
          const appointmentTagNameList = appointment.tags.map(
            (tag) => tag.tag.name
          );
          return _.intersection(appointmentTagNameList, tags).length > 0;
        });
      }

      const result = list.map((event) => appointmentToBookedEvent(event));

      return result?.sort((a, b) => {
        if (a.start.isBefore(b.start)) {
          return -1;
        } else if (dayjs(a.start).isAfter(dayjs(b.start))) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }, [appointmentList, tags]);

  const availableSlotsMemo: AvailableSlots[] = useMemo(() => {
    const result: AvailableSlots[] = [];

    if (bookedSlotsMemo && service && staffId) {
      enumerateDatesInBetween(dateRange.start, dateRange.end).forEach(
        (date) => {
          const currentDayOpeningHours = openingHours?.find(
            (oh) => oh.translation.en == date.format("dddd").toLocaleLowerCase()
          );

          if (!currentDayOpeningHours || date.add(1, "day").isBefore(dayjs()))
            return;

          const openFrom = setDateOnTimeString(
            currentDayOpeningHours?.from,
            date
          );
          const openTo = setDateOnTimeString(currentDayOpeningHours?.to, date);

          const availableSlots = getAvailableSlots(
            openFrom,
            openTo,
            service?.duration,
            bookedSlotsMemo
          );

          result.push({
            date,
            info: { total: availableSlots.length, values: availableSlots },
          });
        }
      );

      return result;
    }
    return [];
  }, [bookedSlotsMemo, service, staffId, dateRange, openingHours, tags]);

  // useEffect(() => {
  //   setEvents([]);
  // }, [view]);

  //we don't need base.view because it triggers refetching of appointment list already
  const eventss = useMemo(() => {
    let result: Event[];

    if (view == "month")
      result = availableSlotToMonthlyView(availableSlotsMemo);
    else if (view == "day" && dayView == "available")
      result = availableSlotsToMeelyView(availableSlotsMemo);
    else if (view == "day" && dayView == "booked") result = bookedSlotsMemo;

    setEvents(result);
    return result;
  }, [bookedSlotsMemo, availableSlotsMemo, dayView]);

  const calendarRenderer = useMemo(() => {
    if (view && dayView && events)
      return (
        <BigCalendar
          views={{ day: DayViewComponent, month: true }}
          view={view}
          dayView={dayView}
          className="flex w-full h-full overflow-scroll"
          events={events}
        />
      );
  }, [view, dayView, events]);

  return (
    <div className="flex flex-col gap-1 w-full ">
      <div className="flex flex-col gap-1 w-full h-full">
        <div className="flex  w-full h-full">
          <div className="flex flex-col gap-1 lg:flex-row w-full">
            <div className="flex flex-col justify-start lg:w-1/4 ">
              <div className="flex flex-col lg:flex-col-reverse gap-1">
                {view == "day" && (
                  <Widget className="flex items-center h-14 px-4">
                    <div className="flex flex-col w-full ">
                      <div className="flex  items-end w-full">
                        <Button
                          type="text"
                          className={classNames(
                            "flex justify-start items-center w-28 gap-2  capitalize "
                          )}
                          onClick={() => setView("month")}
                        >
                          <span>
                            <Icons.Back />
                          </span>
                          calendar
                        </Button>
                      </div>
                    </div>
                  </Widget>
                )}
                <Widget className="lg:h-96 py-4">
                  <Toolbar
                    className={classNames("h-full w-full")}
                    animeComplete={animeComplete}
                  />
                </Widget>
              </div>
            </div>

            <Widget className="lg:w-3/4 h-full">{calendarRenderer}</Widget>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
