import { useShow } from "@refinedev/core";
import Form from "@src/components/pages/staff/Form";
import Widget from "@src/components/Widget";
import { Staff } from "@src/types";
import React from "react";

const Edit = () => {
  const staff = useShow<Staff>({
    metaData: { fields: ["authId"] },
  }).queryResult.data?.data;

  const defaultValues: Partial<Staff> = {
    authId: "",
    color: "#000000",
    title: "",
    authUser: {
      roles: [{ role: "org.staff" }],
      avatarUrl: "",
      password: "Reset@123",
      defaultRole: "org.staff",
    },
  };

  return (
    <Widget className="w-full overflow-scroll">
      {staff && (
        <Form
          formType="edit"
          defaultValues={defaultValues}
          authId={staff.authId}
        />
      )}
    </Widget>
  );
};

export default Edit;
