import StaticMap from "@src/components/StaticMap";
import { Adress } from "@src/types";
import { Card as AntdCard } from "antd";
import React from "react";

const { Meta } = AntdCard;

type Props = {
  adress: Adress;
};

const Card = ({ adress }: Props) => {
  const locationCoordinates = () => {
    const parsedLatLng = adress.latLng
      .slice(1, -1)
      .split(",")
      .map((x) => parseFloat(x));

    return {
      lat: parsedLatLng[0],
      lng: parsedLatLng[1],
    };
  };

  return (
    <AntdCard
      cover={
        <div
          style={{ height: "100%", width: "100%", flex: 1, display: "flex" }}
        >
          <StaticMap latLng={adress.latLng} />
        </div>
      }
      // actions={[<EditOutlined key="edit" onClick={() => {}} />, ""]}
      hoverable={true}
    >
      <Meta
        title={`${adress.city?.en}, ${adress.area.en}`}
        description={adress.description}
      />
    </AntdCard>
  );
};

export default Card;
