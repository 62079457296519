import { RcFile } from "antd/es/upload";
import Resizer from "react-image-file-resizer";

export const resizeFile = (
  file: File,
  width: number,
  height: number
): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "WEBP",
      70,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
