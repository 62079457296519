import ImageLoader from "@src/components/ImageLoader";
import React from "react";
import { Helmet } from "react-helmet";

function Signup() {
  return (
    <div className="flex h-screen w-full lg:mt-0 lg:items-center justify-center bg-gray-100">
      <Helmet>
        <title>{`Signup`} </title>
      </Helmet>

      <div className="absolute flex justify-center items-center w-full h-full px-2 ">
        <div className="flex flex-col justify-around items-center w-full max-w-sm h-5/6 p-8 rounded-lg shadow-2xl bg-white backdrop-blur">
          <div className="flex h-28 w-28 border-0">
            <ImageLoader
              storageId="c0d80f9b-0e68-48ff-b2f3-9e68a034a093"
              className="w-full rounded-md"
              objectFit="object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
