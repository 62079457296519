import { handleDTimeSelectVisibleChange } from "@src/utils/dropdown";
import { TimePicker as AntdTimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

type Props = {
  value: Dayjs;
  onChange: (...event: any[]) => void;
};

const format = "h:mm a";

const TimePicker = ({ value, onChange }: Props) => {
  return (
    <AntdTimePicker
      showSecond={false}
      minuteStep={15}
      hourStep={1}
      value={value}
      onChange={(val) => {
        console.log("bla", val);
        const bla = val
          ? val?.format("HH:mm:ssZ")
          : dayjs().startOf("day").format("HH:mm:ssZ");

        onChange(bla);
      }}
      changeOnBlur={true}
      showNow={false}
      format={format}
      use12Hours={false}
      inputReadOnly={true}
      onOpenChange={(val) => handleDTimeSelectVisibleChange(val)}
      className=""
      size="small"
      defaultValue={dayjs().startOf("day")}
    />
  );
};

export default TimePicker;
