import { GraphQLClient } from "@refinedev/hasura";
import { GraphQLError } from "graphql";

const client = new GraphQLClient("");

export interface Response<T> {
  data: T;
  extensions?: unknown;
  headers: any;
  errors?: GraphQLError[];
  status: number;
}

// async function responseMiddleware(response: Response<unknown> | Error) {
//   const res = response;

//   if (
//     // !window.location.pathname.includes("client") &&
//     response instanceof Error &&
//     response.message.includes(
//       "Missing 'Authorization' or 'Cookie' header in JWT authentication"
//     )
//   ) {
//     await refreshToken().catch((e) => console.log("refresh failed:", e));
//   }

//   return {
//     ...response,
//   };
// }

export class GqlClient {
  private static API_URL = `/api/v1/graphql`;
  private static instance: GqlClient;

  client: GraphQLClient;

  private constructor() {
    this.client = new GraphQLClient(GqlClient.API_URL);
  }

  public static getInstance(): GqlClient {
    if (!GqlClient.instance) {
      GqlClient.instance = new GqlClient();
    }

    return GqlClient.instance;
  }

  // public static setHeaders() {
  //   const authUser: Identity = getUserIdentity();

  //   GqlClient.instance.client.setHeaders({
  //     "content-type": "application/json",
  //     authorization: "Bearer " + authUser?.accessToken,
  //   });
  // }
}
