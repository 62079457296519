import { useNavigation, useTable } from "@refinedev/core";
import Filterheader from "@src/components/Filterheader";
import CardList from "@src/components/pages/customer/CardList";
import TableNav from "@src/components/TableNav";
import Widget from "@src/components/Widget";
import { Customer } from "@src/types";
import React, { useState } from "react";

const List = () => {
  const { create } = useNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    current,
    setCurrent,
    setFilters,
    tableQueryResult: { data: tableData },
    pageSize,
  } = useTable<Customer>({
    meta: {
      fields: ["id", "name", "mobile", "gender"],
    },

    pagination: {
      current: 1,
      pageSize: 8,
    },
  });

  //TODO get users from auth service based on list
  const filterHandler = (val: string) => {
    setCurrent(1);

    if (val === "") setFilters([], "replace");
    else
      setFilters([
        {
          field: "name",
          operator: "contains",
          value: `%${val}%`,
        },
      ]);
  };

  return (
    // <div className="flex flex-col h-full w-full">
    <Widget className="flex flex-col justify-between w-full h-full p-4">
      <div className="h-20">
        <Filterheader
          componentName="customer"
          filterHandler={filterHandler}
          addButtonText="add"
          addButtonAction={() => setIsModalOpen(true)}
        />
      </div>

      <div className="flex w-full pt-4 md:pt-0">
        <CardList customerList={tableData?.data} />
      </div>

      <TableNav
        current={current}
        setCurrent={setCurrent}
        pageSize={pageSize}
        total={tableData?.total}
      />
    </Widget>
  );
};

export default List;
