import jwt_decode from "jwt-decode";

type AccessToken = {
  exp: number;
};

// export const isTokenExpired = (token: string) => {
//   try {
//     const decodedToken: AccessToken = jwt_decode(token);
//     if (!decodedToken) {
//       return true; // Token could not be decoded
//     }

//     const currentTime = Date.now() / 1000; // Convert to seconds

//     if (decodedToken.exp && decodedToken.exp < currentTime) {
//       return true; // Token has expired
//     }

//     return false; // Token is still valid
//   } catch (error) {
//     console.error("Error decoding JWT:", error);
//     return true; // Error occurred while decoding token
//   }
// };

// export const getRoleFromAcessToken = () => {
//   return "";
// };

export const getOrgIdFromAcessToken = (token: string) => {
  try {
    const accessTokenDecoded: any = jwt_decode(token);
    const orgId =
      accessTokenDecoded["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

    return orgId;
  } catch (e) {
    console.log(e);
  }
};

// export const refreshToken = async () => {
//   return axios.get("/auth/token/refresh").then((resp) => resp);
//   // .catch(() => {
//   //   window.location.pathname = "/login";
//   //   return null;
//   // });
// };

// export const logout = async () => {
//   return axios.post("/auth/logout");
// };
