import { handleDropdownVisibleChange } from "@src/utils/dropdown";
import { Select as AntdSelect } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import classNames from "classnames";
import React, { Dispatch, SetStateAction } from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
  placeholder?: string;
  mode?: "multiple" | "tags";
  disabled?: boolean;
  defaultItem?: string;
  defaultItemDisabled?: boolean;
  onChange?: Dispatch<SetStateAction<string>>;
  value?: string;
  className?: string;
  size?: SizeType;
  defaultValue?: string;
};

export const Option = AntdSelect.Option;

const Select = ({
  children,
  placeholder,
  mode,
  disabled,
  defaultItem,
  defaultItemDisabled,
  onChange,
  value,
  className,
  size,
  defaultValue,
}: Props) => {
  return (
    <AntdSelect
      placeholder={placeholder}
      popupClassName="z-20"
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      className={classNames(className)}
      size={size}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      optionLabelProp="label"
    >
      {children}
    </AntdSelect>
  );
};

export default Select;
