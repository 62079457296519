import classNames from "classnames";
import React, { FC } from "react";

type Props = {
  className?: string;
  value: string | number;
  name: string;
};

const ShowField: FC<Props> = ({ className, value, name }) => {
  return (
    <div className={classNames(className)}>
      <label className="mb-2 block text-sm">{name}</label>
      <input
        type="text"
        defaultValue={value}
        disabled
        className="input input-bordered input-primary w-full"
      />
    </div>
  );
};

export default ShowField;
