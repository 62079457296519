import { InputNumber as AntdInputNumber } from "antd";
import classNames from "classnames";
import React, { FC, forwardRef } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

type Props = {
  inputType: "input" | "input-group" | "numeric" | "input-formated";
  onChange: (...event: any[]) => void;
  value: string;
  label: string;
  name: string;
  className?: string;
  disabled?: boolean;
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  type?: "text" | "password" | "number";
  defaultValue?: string;
  hidden?: boolean;
  unit?: "IQD" | "MIN";
  addonAfter?: string;
  autoFocus?: boolean;
  step?: number;
  // format?: string;
};

const InputNumber: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      value,
      defaultValue,
      label,
      name,
      className,
      errors,
      type = "text",
      disabled = false,
      hidden = false,
      autoFocus = false,
      addonAfter,
      step,
    },
    ref
  ) => {
    return (
      <div className={className}>
        {!hidden && (
          <label
            htmlFor="street"
            className="mb-2 block font-light text-sm lowercase"
          >
            {label}
          </label>
        )}

        <AntdInputNumber
          step={step}
          type={type}
          id={name}
          className={classNames(className, "")}
          // placeholder={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          hidden={hidden}
          autoFocus={autoFocus}
          size="large"
          addonAfter={addonAfter}
        />
        {errors && (
          <p className="mt-1 text-sm">
            <span className="font-medium">Oops!</span> This field is required
          </p>
        )}
      </div>
    );
  }
);

export default InputNumber;
