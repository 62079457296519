import { Identity } from "@src/types";

export const itemName = "m3-auth";

//TODO fix this
export const getRole = (): string => {
  const authUser = JSON.parse(localStorage.getItem(itemName)) as Identity;

  return authUser?.profile?.role.name;
};

export const getOrgId = (): string => {
  const authUser = JSON.parse(localStorage.getItem(itemName)) as Identity;

  return authUser?.profile.role.orgId;
};

export const getUserIdentity = (): Identity | undefined => {
  return JSON.parse(localStorage.getItem(itemName)) as Identity;
};
