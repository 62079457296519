import { useList } from "@refinedev/core";
import { Customer } from "@src/types";
import { DailyEvent } from "@src/types/views";
import { formatPrice } from "@src/utils/formatter";
import dayjs from "dayjs";
import ms from "ms";
import React from "react";
import Icons from "./Icons";

type Props = {
  events: DailyEvent[];
};

const Statistics = ({ events }: Props) => {
  const todayEvents = events
    .filter((event) => event.date === dayjs().format("YYYYMMDD"))
    .map((event) => event.count);

  const currentMonthEvents = events
    .filter(
      (event) => dayjs(event.date, "YYYYMMDD").month() === dayjs().month()
    )
    .map((event) => event.count)
    .reduce((acc, count) => acc + count, 0);

  const todayRevenew = events
    .filter((event) => event.date === dayjs().format("YYYYMMDD"))
    .map((event) => event.revenew);

  const currentMonthRevenew = events
    .filter(
      (event) => dayjs(event.date, "YYYYMMDD").month() === dayjs().month()
    )
    .map((event) => event.revenew)
    .reduce((acc, revenew) => acc + revenew, 0);

  const previousMonthEvents = events
    .filter(
      (event) => dayjs(event.date, "YYYYMMDD").month() === dayjs().month() - 1
    )
    .map((event) => event.count)
    .reduce((acc, count) => acc + count, 0);

  const { data: customers } = useList<Customer>({
    resource: "customer",
    metaData: { fields: ["id"] },
    queryOptions: { refetchInterval: ms(process.env.REFRESH_INTERVAL) },
    config: {},
  });

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:min-h-[6rem]">
      <div className="flex flex-col items-center justify-center bg-white rounded-md p-1 h-20 md:h-full">
        <Icons.Booking className="opacity-60" />
        <p className="capitalize text-xs opacity-60">today </p>
        <p className="text-3xl font-bold">{todayEvents[0] || 0}</p>
      </div>

      <div className="flex flex-col items-center justify-center bg-white rounded-md p-1 md:h-full">
        <Icons.Booking className="opacity-60" />
        <p className="capitalize text-xs opacity-60">this month </p>
        <p className="text-3xl font-bold">{currentMonthEvents || 0}</p>
      </div>

      <div className="flex flex-col items-center justify-center bg-white rounded-md p-1 h-20 md:h-full">
        <Icons.Money className="opacity-60" />
        <p className="capitalize text-xs opacity-60 ">today </p>
        <p className="text-2xl font-bold">
          {formatPrice(todayRevenew[0]) || 0}
          {/* <span className="text-sm mx-1">IQD</span> */}
        </p>
      </div>

      <div className="flex flex-col items-center justify-center bg-white rounded-md p-1 md:h-full">
        <Icons.Money className="opacity-60" />
        <p className="capitalize text-xs opacity-60">this month </p>
        <p className="text-2xl font-bold">
          {formatPrice(currentMonthRevenew) || 0}
        </p>
      </div>
    </div>
    // <div className="flex w-full justify-evenly py-2  border-base-200 rounded-lg">
    //  <div className="flex flex-col w-1/3 items-center">
    //   <div className="capitalize text-sm text-gray-500">today</div>
    //   <div className="text-4xl font-bold">{todayTotalEvents[0] || 0}</div>
    //   <div className="text-gray-500 text-xs">{dayjs().format("MMM DD")}</div>
    // </div>

    // <div className="py-2 h-full">
    //   <Divider className="h-full" type="vertical" />
    // </div>

    // <div className="flex flex-col w-1/3 items-center">
    //   <div className="capitalize text-sm text-gray-500">this month</div>
    //   <div className="text-4xl font-bold">{currentMonthTotalEvents || 0}</div>
    //   <div className="text-gray-500 text-xs">
    //     {formatMonthlyEvents(
    //       currentMonthTotalEvents,
    //       previousMonthTotalEvents
    //     )}
    //   </div>
    // </div>

    // <div className="py-2 h-full">
    //   <Divider className="h-full" type="vertical" />
    // </div>

    // <div className="flex flex-col w-1/3 items-center">
    //   <div className="capitalize text-sm text-gray-500">Customers</div>
    //   <div className="text-4xl font-bold">{customers?.total}</div>
    // </div>
    // </div>
  );
};

const formatMonthlyEvents = (
  currentMonthCount: number,
  previousMonthCount: number
) => {
  const diff = currentMonthCount - previousMonthCount || 0;
  let percentage = (diff || 1 / previousMonthCount || 1) * 100;
  percentage = Math.ceil(percentage);

  if (diff >= 0)
    return (
      <p>
        ↗︎ {diff} ({percentage}%)
      </p>
    );
  else
    return (
      <p>
        ↘︎ {diff} {percentage}%
      </p>
    );
};

export default Statistics;
