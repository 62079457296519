import { toast } from "react-toastify";

export const successMessage = (content: string) => {
  toast(content, {
    autoClose: 1000,
    hideProgressBar: true,
    type: "success",
    position: "top-right",
  });
};

export const errorMessage = (content: string) => {
  toast(content, {
    autoClose: 1000,
    hideProgressBar: true,
    type: "error",
    position: "top-right",
  });
};
