import {
  getAuthToken,
  isSubscribed as magicBellIsSubscribed,
  subscribe,
} from "@magicbell/webpush";
import { Checkbox } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

type Props = {
  staffId: string;
};

const NotificationCheckBox = ({ staffId }: Props) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const magicbelApiKey = process.env.API_KEY;
  const magicbelApiSecret = process.env.API_SECRET;

  const userCredentials = {
    apiKey: magicbelApiKey,
    userExternalId: staffId,
  };

  useEffect(() => {
    getAuthToken(userCredentials).then((authToken) => {
      const { token, project, host } = authToken;

      magicBellIsSubscribed({
        token,
        project,
        host,
      })
        .then((resp: boolean) => setIsSubscribed(resp))
        .catch((e) => console.log("error", e));
    });
  }, []);

  const getAndSetAuthToken = async () => {
    getAuthToken(userCredentials).then((res: any) => {
      setAuthToken(res);
    });
  };

  const handleSubscribe = async () => {
    const token = await getAuthToken(userCredentials);

    subscribe(token)
      .then(() => setIsSubscribed(true))
      .catch((e) => alert(e));
  };

  const handleDeleteSubscribe = async () => {
    axios
      .delete(`https://api.magicbell.com/users/external_id:${staffId}`, {
        headers: {
          "X-MAGICBELL-API-KEY": magicbelApiKey,
          "X-MAGICBELL-API-SECRET": magicbelApiSecret,
        },
      })
      .then(() => setIsSubscribed(false))
      .catch((e) => console.error(e));
  };

  return (
    <div>
      {staffId && (
        <Checkbox
          className="mt-4"
          onChange={(e) =>
            e.target.checked ? handleSubscribe() : handleDeleteSubscribe()
          }
          checked={isSubscribed}
        >
          notifications enabled
        </Checkbox>
      )}
    </div>
  );
};

export default NotificationCheckBox;
