import { Service } from "@src/types";
import { SizeType } from "antd/es/config-provider/SizeContext";
import classNames from "classnames";
import { capitalize } from "lodash";
import React, { Dispatch, FC, SetStateAction } from "react";
import Select, { Option } from "./Select";

type Props = {
  className?: string;
  onChange: Dispatch<SetStateAction<string>>;
  value?: string;
  disabled?: boolean;
  staffId?: string;
  size?: SizeType;
  items: Service[];
};

const ServiceSelect: FC<Props> = ({
  className,
  onChange,
  value,
  disabled = false,
  staffId,
  size,
  items,
}) => {
  // const serviceList = useList<Service>({
  //   resource: "service",
  //   meta: { fields: ["id", "name", "duration"] },
  //   filters: [{ field: "staffId", operator: "eq", value: staffId }],
  //   queryOptions: { enabled: !!staffId },
  // }).data?.data;

  // useEffect(() => {
  //   if (serviceList?.length == 0) onChange("");
  // }, [serviceList]);

  return (
    <Select
      className={classNames("text-center", className)}
      onChange={(value) => onChange(value)}
      value={value}
      disabled={disabled}
      size={size}
    >
      {items?.map((item, idx) => (
        <Option
          key={idx}
          value={item.id}
          label={capitalize(`${item.name} - ${item.duration}min`)}
        >
          <p className="flex w-full  items-center justify-center">
            {`${item.name} - ${item.duration}min`}
          </p>
        </Option>
      ))}
    </Select>
  );
};

export default ServiceSelect;
