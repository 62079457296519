import { Input as AntdInput } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import classNames from "classnames";
import React, { FC, forwardRef } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import Label from "../Label";

type Props = {
  onChange: (...event: any[]) => void;
  value: string | number;
  label?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  type?: "text" | "password" | "number";
  defaultValue?: string;
  hidden?: boolean;
  addonAfter?: string;
  autoFocus?: boolean;
  // format?: string;
  placeholder?: string;
  size?: SizeType;
  onBlur?: (...event: any[]) => void;
};

const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      value,
      defaultValue,
      label,
      name,
      className,
      errors,
      type = "text",
      disabled = false,
      hidden = false,
      autoFocus = false,
      addonAfter,
      placeholder,
      size = "middle",
      onBlur,
    },
    ref
  ) => {
    return (
      <div className={className}>
        {!hidden && <Label label={label} />}

        <AntdInput
          type={type}
          id={name}
          className={classNames(className, "")}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          disabled={disabled}
          hidden={hidden}
          autoFocus={autoFocus}
          size={size}
          addonAfter={addonAfter}
          onBlur={onBlur}
        />
        {errors && (
          <p className="mt-1 text-xs text-red-500">
            {errors.message as string}
          </p>
        )}
      </div>
    );
  }
);

export default Input;
