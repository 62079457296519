const regex = /([\w]{8})(-[\w]{4}){3}-([\w]{12})/

export const getUuidLastPart = (uuid: string) => {
  const match = uuid.match(regex);

  return match[3];
};


export const getUuidFirstPart = (uuid: string) => {
  const match = uuid.match(regex);

  return match[1];
};
