import { createHook, createStore } from "react-sweet-state";

type StoreObject = {
  visible: boolean;
};

const initialState: StoreObject = {
  visible: false,
};

const Store = createStore({
  initialState,
  actions: {
    set:
      (visible: boolean) =>
      ({ setState, getState }) => {
        setState({
          visible: visible,
        });
      },
  },
  name: "mainMenuDrawer",
});

export const useMainMenuDrawer = createHook(Store);
