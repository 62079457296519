import { useDelete, useNavigation, useOne } from "@refinedev/core";
import ImageLoader from "@src/components/ImageLoader";
import nhost from "@src/lib/nhost";
import { Image, Service } from "@src/types";
import { AuthUserRes } from "@src/types/auth-user";
import { formatPrice } from "@src/utils/formatter";
import { getUuidLastPart } from "@src/utils/regex";
import { Card as AntdCard } from "antd";
import Meta from "antd/es/card/Meta";
import React, { FC, useMemo } from "react";

type Props = {
  service: Service;
};

const Card: FC<Props> = ({ service }) => {
  const { mutate } = useDelete();
  const { edit } = useNavigation();

  const staffAuthUserData = useOne<AuthUserRes>({
    resource: "user",
    dataProviderName: "nhostObjects",
    id: service.staff.authId,
    meta: { fields: ["id", "displayName"] },
  }).data?.data;

  const image = useOne<Image>({
    resource: "image",
    id: service.avatarUrl,
    meta: { fields: ["storageId"] },
    queryOptions: { enabled: !!service.avatarUrl },
  }).data?.data;

  const imageUrl = useMemo(() => {
    if (image)
      return nhost.storage.getPublicUrl({
        fileId: image?.storageId,
      });
  }, [image]);

  return (
    <AntdCard
      cover={
        <div className="flex h-56">
          <ImageLoader
            src={imageUrl}
            className="h-full w-full rounded-t-md border-none"
            objectFit="object-cover"
          />
        </div>
      }
      // actions={[<EditOutlined key="edit" onClick={() => {}} />, ""]}
      hoverable={true}
      onClick={() => edit("service", service.id)}
    >
      <Meta
        title={
          <div className="flex w-full">
            <div className="flex flex-col justify-center">
              <h2 className="card-title uppercase">{service?.name}</h2>
              <p className="uppercase text-xs opacity-50">
                {getUuidLastPart(service.id)}
              </p>
            </div>
          </div>
        }
        description={
          <div className="h-20">
            <div className="flex flex-col justify-around w-full h-full ">
              {/* <div className="flex w-full h-1/3 ">
                <div className="flex flex-col justify-center">
                  <h2 className="card-title uppercase">{service?.name}</h2>
                  <p className="uppercase text-xs">
                    {getUuidLastPart(service.id)}
                  </p>
                </div>
              </div> */}

              <div className="flex w-full justify-between h-1/3">
                <div className="flex items-center h-full w-1/2 ">
                  <span className="text-xl text-black">{service.duration}</span>
                  <span className="opacity-50 text-sm pt-1">min</span>
                </div>

                <div className="flex justify-end items-center h-full w-1/2 ">
                  <span className="text-xl text-black">
                    {formatPrice(service.price)}
                  </span>
                  <span className="opacity-50 text-xs pt-1">IQD</span>
                </div>
              </div>

              <div className="flex gap-2 justify-end items-end w-full h-1/3 italic">
                <span className=""></span>
                <span className=" capitalize ">
                  {staffAuthUserData?.displayName}
                </span>
              </div>
            </div>
          </div>
        }
      />
    </AntdCard>

    // <div className="flex relative flex-col w-full h-80 max-w-xs border hover:shadow-md rounded-md">
    //   <div className="flex absolute h-full w-full justify-end z-10 ">
    //     <Icons.Edit
    //       size={18}
    //       className="text-orange-200 cursor-pointer absolute top-2 left-2"
    //       onClick={() => edit("service", service.id)}
    //     />
    //   </div>

    //   <div className="flex h-1/2 ">
    //     <ImageLoader
    //       src={imageUrl}
    //       className="h-full w-full rounded-t-md border-none"
    //       objectFit="object-cover"
    //     />
    //   </div>

    //   <Divider dashed className="-my-0" />

    //   <div className="h-1/2 p-4">
    //     <div className="flex flex-col w-full h-full ">
    //       <div className="flex w-full h-1/3 ">
    //         <div className="flex flex-col justify-center">
    //           <h2 className="card-title uppercase">{service?.name}</h2>
    //           <p className="uppercase text-xs opacity-50">
    //             {getUuidLastPart(service.id)}
    //           </p>
    //         </div>
    //       </div>

    //       <div className="flex w-full justify-between h-1/3">
    //         <div className="flex  items-center h-full w-1/2 ">
    //           <span className="text-xl">{service.duration}</span>
    //           <span className="opacity-50 text-sm pt-2">min</span>
    //         </div>

    //         <div className="flex justify-end items-center h-full w-1/2 ">
    //           <span className="text-xl">{formatPrice(service.price)}</span>
    //           <span className="opacity-50 text-sm pt-2">IQD</span>
    //         </div>
    //       </div>

    //       <div className="flex gap-2 justify-end items-end w-full h-1/3 opacity-50 italic">
    //         <span className=""></span>
    //         <span className=" capitalize ">
    //           {staffAuthUserData?.displayName}
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Card;
