import { LayoutProps, useParsed } from "@refinedev/core";
import MainMenu from "@src/components/MainMenu";
import MobileDrawer from "@src/components/MobileDrawer";
import TopMenu from "@src/components/TopMenu";
import Widget from "@src/components/Widget";
import { darkModeAtom } from "@src/store/app";
import { theme } from "antd";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [darkModeValue, setDarkMode] = useAtom(darkModeAtom);
  const [bgColor, setBgColor] = useState(null);
  const { resource } = useParsed();

  useEffect(() => {
    setBgColor(colorBgContainer);
  }, [darkModeValue]);

  return (
    <>
      <Helmet>
        <title className="capitalize">
          {resource.name.charAt(0).toUpperCase() + resource.name.slice(1)}
        </title>
      </Helmet>

      <MobileDrawer children={<MainMenu />} />
      <div
        className="flex w-full font-roboto min-h-screen rounded-none"
        style={{ background: "#F2F2F2" }}
      >
        <div className="fixed lg:flex hidden md:w-1/5 min-h-screen justify-center px-2 py-4 ">
          <Widget className="rounded-lg w-full">
            <MainMenu />
          </Widget>
        </div>
        <div className="flex w-screen lg:max-h-screen md:px-10 ">
          <div className="h-full lg:w-1/5 w-0"></div>
          <div className="flex flex-col gap-1 lg:w-4/5 w-full">
            <div className="flex relative w-full">
              <TopMenu className="fixed md:relative h-24 md:h-16 z-20" />
            </div>
            <div className="flex h-full min-h-[calc(100%-7rem)] md:max-h-[calc(100%-4rem)] md:pb-4 pb-2 pt-24 md:pt-0 px-[2px] md:px-0">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
