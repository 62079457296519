import { MemoryAdapter, newModel } from "casbin.js";

export const model = newModel(`
[request_definition]
r = sub, obj, act
[policy_definition]
p = sub, obj, act, eft
[role_definition]
g = _, _
[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))
[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new MemoryAdapter(`
p, org.admin, dashboard, (list)
p, org.admin, org, (list)|(edit)
p, org.admin, branch, (list)|(create)|(show)|(delete)
p, org.admin, staff, (list)|(create)|(show)|(edit)|(delete)
p, org.admin, service, (list)|(create)|(show)|(edit)|(delete)
p, org.admin, customer , (list)|(create)|(show)|(delete)|(edit)
p, org.admin, user, (list)|(create)|(show)|(edit)|(delete)
p, org.admin, tag, (list)|(create)|(show)|(edit)|(delete)
p, org.admin, booking, (list)|(create)|(show)|(edit)|(delete)
p, org.admin, storage , (list)|(create)|(show)|(delete)
p, org.admin, openinghours , (list)|(create)|(show)|(delete)

p, org.admin, staff/orgId, field, deny
p, org.admin, service/orgId, field, deny
p, org.admin, staff/select, field, allow

##########################################

p, org.staff, dashboard, (list)
p, org.staff, service, (list)|(create)|(show)|(edit)|(delete)
p, org.staff, booking, (list)|(create)|(show)|(delete)
p, org.staff, staff, (list)|(show)|(edit)|(delete)

p, org.staff, staff/select, field, deny

##########################################
p, admin, dashboard, list
p, admin, org, (list)|(create)|(edit)|(show)|(delete)
p, admin, staff, (list)|(create)|(edit)|(show)|(delete)
p, admin, service, (list)|(create)|(edit)|(show)|(delete)
p, admin, customer, (list)|(create)|(edit)|(show)|(delete)
p, admin, booking, (list)|(create)|(edit)|(show)|(delete)
p, admin, storage ,(list)|(create)|(show)|(edit)|(delete)


`);
