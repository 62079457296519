import { useList } from "@refinedev/core";
import TimePicker from "@src/components/TimePicker";
import { OpeningHour, Translation } from "@src/types";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

type Props = {
  control: Control<FieldValues, {}>;
  fields: Record<"id", string>[];
  openingHours?: OpeningHour[];
  setValue?: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
};

type Test = {
  from: Dayjs;
  to: Dayjs;
};

const weekDays = [
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];

function NewRow({ control, fields, openingHours, setValue, watch }: Props) {
  const existingOpeningHours = useList({
    resource: "opening_hour",
    meta: {
      fields: ["id", "from", "to", "translationId", { translation: ["en"] }],
    },
  }).data?.data;

  const translations = useList<Translation>({
    resource: "translation",
    meta: { fields: ["id", "en"] },
    filters: [{ field: "typeId", operator: "eq", value: "week_day" }],
  }).data?.data;

  //   console.log(translations);
  const openingHoursList = watch("openingHours") as OpeningHour[];

  useEffect(() => {
    const tt = weekDays.map((day, dayIdx) => {
      const index = _.findIndex(openingHours, {
        translation: { en: day },
      });

      const oh = existingOpeningHours?.find((oh) => {
        return oh.translation?.en === day;
      });

      const translationId = translations?.find((tr) => tr.en == day).id;

      return {
        id: oh?.id,
        from: oh?.from ?? dayjs(Date.now()).startOf("day").format("HH:mm:ssZ"),
        to: oh?.to ?? dayjs(Date.now()).startOf("day").format("HH:mm:ssZ"),
        translationId,
      };

      //   console.log(day, dayIdx, index);
    });

    // console.log(tt);
    setValue("openingHours", tt);
  }, [existingOpeningHours, translations]);

  useEffect(() => {
    // console.log(watch("openingHours"));
  }, [watch("openingHours")]);

  return (
    <div className="flex flex-col gap-1">
      {openingHoursList.map((oh, key) => (
        <div key={key} className="flex  gap-y-1 gap-x-1">
          <p className="w-16 italic opacity-70">
            {_.find(translations, { id: oh.translationId })?.en.substring(0, 3)}
          </p>

          <Controller
            name={`openingHours.${key}.from`}
            control={control}
            render={({ field }) => (
              <TimePicker
                value={dayjs(oh.from, "hh:mmZ")}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name={`openingHours.${key}.to`}
            control={control}
            render={({ field }) => (
              <TimePicker
                value={dayjs(oh.to, "hh:mmZ")}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      ))}
    </div>
  );

  //   return (
  //     <>
  //       {weekDays.map((weekDay, idx) => {
  //         const tt = openingHours?.find((oh) => {
  //           return oh.translation.en === weekDay;
  //         });

  //         var index = _.findIndex(openingHours, {
  //           translation: { en: weekDay },
  //         });

  //         index = index === -1 ? idx : index;

  //         const { from, to } = watch(`openingHours.${index}`) || {
  //           from: dayjs(Date.now()).startOf("day"),
  //           to: dayjs(Date.now()).startOf("day"),
  //         };

  //         // index = index == -1 ? openingHours?.length + 1 : index;

  //         // console.log(index, index == -1 && idx);
  //         // console.log("watch--->>>", openingHours[0].translation.en);

  //         return (
  //           <div className="flex">
  //             <p className="w-32">{weekDay}</p>

  //             <Controller
  //               name={`openingHours.${index}.from`}
  //               control={control}
  //               render={({ field }) => (
  //                 <TimePicker
  //                   value={dayjs(from, "hh:mmZ")}
  //                   onChange={field.onChange}
  //                 />
  //               )}
  //             />

  //             {/* <TimePicker
  //               value={dayjs(from, "hh:mmZ")}
  //               onChange={(val) => setValue(`openingHours.${index}.from`, val)}
  //             /> */}
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
}

export default NewRow;

// {fields.map((item, index) => {
//     return (
//       <div key={item.id} className="flex gap-2">
//         <div>
//           <Controller
//             name={`openingHours.${index}.from`}
//             control={control}
//             render={({ field }) => (
//               <TimePicker
//                 value={
//                   field.value
//                     ? dayjs(field.value, "hh:mmZ")
//                     : dayjs(Date.now()).startOf("day")
//                 }
//                 onChange={field.onChange}
//               />
//             )}
//           />
//         </div>

//         <div>
//           <Controller
//             name={`openingHours.${index}.to`}
//             control={control}
//             render={({ field }) => (
//               <TimePicker
//                 value={
//                   field.value
//                     ? dayjs(field.value, "hh:mm")
//                     : dayjs(Date.now()).startOf("day")
//                 }
//                 onChange={field.onChange}
//               />
//             )}
//           />
//         </div>
//       </div>
//     );
//   })}
