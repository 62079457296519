import Form from "@src/components/pages/customer/Form";
import Widget from "@src/components/Widget";
import React from "react";

type Props = {};

const Edit = (props: Props) => {
  const defaultValues = {};

  return (
    <Widget className="w-full">
      <Form formType="edit" defaultValues={defaultValues} />
    </Widget>
  );
};

export default Edit;
