import { joiResolver } from "@hookform/resolvers/joi";
import {
  CrudFilters,
  useList,
  useParsed,
  useSelect
} from "@refinedev/core";
import Icons from "@src/components/Icons";
import ImageLoader from "@src/components/ImageLoader";
import { LoadingScreen } from "@src/components/LoadingScreen";
import Modal from "@src/components/user-booking/Modal";
import { Booking, Image, OpeningHour, Org, Service, Staff } from "@src/types";
import { setDateOnTimeString } from "@src/utils/date";
import { getAvailableSlots } from "@src/utils/event";
import { Button, Calendar, Collapse, CollapseProps, Divider } from "antd";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import Joi from "joi";
import { capitalize } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Flip, ToastContainer } from "react-toastify";

type Props = {};

const schema = Joi.object({
  mobile: Joi.string()
    .pattern(/^\d{11}$/)
    .required()
    .messages({
      "string.pattern.base": "invalid mobile number",
    }),

  name: Joi.string().min(3).required(),
}).unknown(true);

type FormType = {
  service: Service;
  staff: Staff;
  date: Dayjs;
  orgId: string;
  mobile: string;
  customerId: string;
  name: string;
};

const index = (props: Props) => {
  const { id } = useParsed();

  const [selectedSlot, setSlectedSlot] = useState<Dayjs>(null);
  const [bookingQueryFilters, setBookingQueryFilters] = useState<CrudFilters>();
  const [open, setOpen] = useState(false);

  const { reset, watch, setValue } = useForm<FormType>({
    defaultValues: {
      service: null,
      staff: null,
      date: null,
      orgId: "",
    },
    resolver: joiResolver(schema),
  });

  const selectedStaff = watch("staff");
  const selectedService = watch("service");
  const selectedDate = watch("date");

  // const { data: orgData, isLoading: orgDataIsLoading } = useOne<Org>({
  //   resource: "org",
  //   id,
  //   meta: {
  //     fields: ["id", "name"],
  //   },
  // });

  const { data: mactchedOrg,isLoading: orgDataIsLoading } = useList<Org>({
    resource:"org",
    meta:{fields:["id","shortId","name"]},
    filters: [
      {
        field: "shortId",
        operator: "eq",
        value: id as string,
      },
    ],
  });

  const orgData = mactchedOrg?.data[0];

  console.log(id,mactchedOrg,orgData);


  const { data: imageList, isLoading: imageListIsLoading } = useList<Image>({
    resource: "image",
    meta: {
      fields: ["id", "type", "storageId"],
    },
    filters: [{ field: "orgId", operator: "eq", value: orgData?.id }],
    pagination: {
      current: 1,
      pageSize: 6,
    },
    queryOptions: { enabled: !!orgData },
  });

  const avatarStorageId = imageList?.data
    .filter((img) => img.type == "avatar")
    .map((img) => img.storageId);

  const orgImageNames = imageList?.data
    .filter((img) => img.type == "org")
    .map((img) => img.storageId);

  const { data: appointmentData, isLoading: appointmenIstLoading } = useList<
    Partial<Booking>
  >({
    resource: "booking",
    meta: { fields: ["start", "end"] },
    queryOptions: { enabled: !!selectedDate && !!selectedStaff },
    pagination: { pageSize: 100 },
    filters: bookingQueryFilters,
  });

  const { data: staffList, isLoading: staffLsitLoading } = useList<Staff>({
    resource: "staff",
    meta: {
      fields: [
        "id",
        "authId",
        "title",
        { staffBranch: ["id", "orgId"] },
        { authUser: ["id", "displayName", "avatarUrl"] },
      ],
    },
    filters: [{ field: "staffBranch.orgId", operator: "eq", value: orgData?.id }],
    queryOptions: { enabled: !!orgData },
  });

  const { data: serviceList, isLoading: serviceListisLoading } =
    useList<Service>({
      resource: "service",
      meta: { fields: ["id", "name", "duration", "avatarUrl"] },
      filters: [{ field: "staffId", operator: "eq", value: selectedStaff?.id }],
      queryOptions: { enabled: !!selectedStaff },
    });

  const openingHours = useSelect<OpeningHour>({
    resource: "opening_hour",
    metaData: { fields: ["from", "to", { translation: ["en"] }] },
    filters: [{ field: "orgId", operator: "eq", value: orgData?.id }],
    queryOptions: { enabled: !!orgData },
  }).queryResult?.data?.data;

  useEffect(() => {
    if (watch("date"))
      setBookingQueryFilters([
        {
          field: "start",
          operator: "gt",
          value: watch("date").startOf("day")?.format("YYYY-MM-DD HH:mm"),
        },
        {
          field: "end",
          operator: "lte",
          value: watch("date").endOf("day")?.format("YYYY-MM-DD HH:mm"),
        },
      ]);

    if (selectedStaff)
      setBookingQueryFilters((prev) => [
        ...prev,
        { field: "staffId", operator: "eq", value: selectedStaff.id },
      ]);
  }, [selectedDate, selectedStaff]);

  const heroRender = () => {
    return (
      <div className="flex flex-col h-full w-full ">
        <div className="flex h-full bg-gradient-to-tr from-[#FFDEE9] to-[#B5FFFC] p-1">
          <div className="h-full w-1/3">
            <div className="flex h-full items-center justify-center">
              <ImageLoader
                storageId={avatarStorageId[0]}
                className="h-14 w-14 rounded-full"
                objectFit="object-cover"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-full w-2/3 opacity-90 ">
            <p className="capitalize text-2xl">{orgData?.name}</p>
            <div className="flex items-center gap-2 text-sm font-light">
              <Icons.HiOutlineLocationMarker size={14} />
              <p className="capitalize ">
                {/* {orgData?.data?.adresses[0]?.city.en} -
                {orgData?.data?.adresses[0]?.area.en} */}
              </p>
              <p className="capitalize"></p>
            </div>
          </div>
        </div>
        <Divider className="my-1" dashed />
      </div>
    );
  };

  const calendarRender = () => {
    return (
      <Calendar
        fullscreen={false}
        className=""
        onSelect={(date, { source }) => {
          if (source === "date") {
            setValue("date", date);
          }
        }}
        disabledDate={(curr) => {
          return disabledDate(curr, openingHours);
        }}
      />
    );
  };

  const staffRender = () => {
    return (
      <div className="flex flex-col gap-4">
        {staffList?.data.map((staff, idx) => (
          <div
            key={idx}
            className="flex h-20 w-full border-b last:border-none p-2"
          >
            <div className="flex justify-center items-center w-1/4 h-full ">
              <ImageLoader
                src={staff.authUser?.avatarUrl}
                objectFit="object-cover"
                className="flex w-10 h-10 rounded-full p-[2px] "
              />
            </div>
            <div className="flex flex-col justify-center items-center w-2/4 h-full ">
              <p className="capitalize">{staff.authUser?.displayName}</p>
              <p className="capitalize opacity-75 text-sm">{staff.title} </p>
            </div>
            <div className="flex items-center justify-center w-1/4 h-full ">
              <Button
                type={selectedStaff?.id == staff.id ? "primary" : "default"}
                className="rounded-3xl capitalize"
                onClick={() => {
                  setValue("staff", staff);
                }}
              >
                select
              </Button>
            </div>
          </div>
        ))}
      </div>
    );
  };


  const serviceRender = () => {
    return (
      <div className="flex flex-col gap-4">
        {serviceList?.data.map((service, idx) => (
          <div
            key={idx}
            className="flex h-20 w-full border-b last:border-none p-2"
          >
            <div className="flex justify-center items-center w-1/4 h-full ">
              <ImageLoader
                storageId={service.avatarUrl}
                className="w-14 h-14 rounded-md"
                objectFit="object-cover"
                // orgId={id as string}
              />
            </div>
            <div className="flex flex-col justify-center items-center w-2/4 h-full ">
              <div className="flex flex-col gap-2 opacity-75">
                <div className="flex gap-2">
                  <p className="capitalize font-semibold text-xs">{`${service.name} (${service.duration}min)`}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-1/4 h-full ">
              <Button
                type={selectedService?.id == service.id ? "primary" : "default"}
                className="rounded-3xl capitalize"
                onClick={() => {
                  setValue("service", service);
                }}
              >
                select
              </Button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const availableSlotsRender = useMemo(() => {
    if (!selectedService || !appointmentData?.data || !selectedStaff) return [];

    const currentDayOpeningHours = openingHours?.find(
      (oh) =>
        oh.translation.en == selectedDate?.format("dddd").toLocaleLowerCase()
    );

    const openFrom = setDateOnTimeString(
      currentDayOpeningHours?.from,
      selectedDate
    );
    const openTo = setDateOnTimeString(
      currentDayOpeningHours?.to,
      selectedDate
    );

    const availableSlots = getAvailableSlots(
      openFrom,
      openTo,
      selectedService.duration,
      appointmentData?.data
    );

    return (
      <div className="grid grid-cols-3 gap-x-4 gap-y-2 ">
        {availableSlots.map((slot, idx) => (
          <div
            key={idx}
            className="flex items-center justify-center w-full border shadow-sm text-center h-8 cursor-pointer rounded-md bg-white text-sm"
            onClick={() => {
              setOpen(true);
              setSlectedSlot(slot.start);
            }}
          >
            {slot.start.format("hh:mm a")}
          </div>
        ))}
      </div>
    );
  }, [selectedDate, selectedStaff, selectedService, appointmentData?.data]);

  const collapseItems: CollapseProps["items"] = [
    {
      key: "0",
      label: "Date",
      children: calendarRender(),
      className: "collapse-header-class",
    },
    {
      key: "1",
      label: "Staff",
      children: staffRender(),
      className: "collapse-header-class",
    },
    {
      key: "2",
      label: "Service",
      children: serviceRender(),
      className: "collapse-header-class",
    },
    {
      key: "3",
      label: "Availabe Slots",
      children: availableSlotsRender,
      className: "collapse-header-class",
    },
  ];

  const activeCollpseItem = useMemo(() => {
    if (!selectedDate) return 0;
    if (selectedDate && !selectedStaff) return 1;
    if (selectedStaff && !selectedService) return "2";
    if (selectedService) return "3";
    return "0";
  }, [selectedStaff, selectedService, selectedDate]);

  // () => {
  //   if (selectedStaff) return 2;
  //   if (selectedService) return 3;
  //   else return 1;
  // };

  const bookingFormRender = () => {
    return (
      <Collapse
        items={collapseItems}
        defaultActiveKey={1}
        expandIcon={({ isActive }) => (
          <Icons.BoxArrow
            size={20}
            className={classNames(
              "text-white rounded-full",
              isActive ? "rotate-180 bg-primary" : "bg-gray-300"
            )}
            rotate={isActive ? 90 : 0}
            color="white"
          />
        )}
        accordion={true}
        activeKey={[activeCollpseItem]}
        expandIconPosition="end"
        // collapsible="header"
        bordered={false}
        ghost={true}
        className="px-0"
      />
    );
  };

  if (!orgData) return <></>;
  else if (orgDataIsLoading || imageListIsLoading) return <LoadingScreen />;

  return (
    <>
      <Helmet>
        <title>{`Mau3id ${capitalize(orgData?.name)}`} </title>
      </Helmet>

      <ToastContainer transition={Flip} />

      <div className="flex flex-col items-center justify-center font-roboto bg-gray-100 md:py-2">
        <Modal
          id={orgData?.id}
          selectedService={selectedService}
          selectedStaff={selectedStaff}
          selectedSlot={selectedSlot}
          // customer={customerData?.data[0]}
          open={open}
          setOpen={setOpen}
          resetParent={reset}
        />

        <div className="flex flex-col gap-2 h-full min-h-screen  w-full max-w-sm bg-white">
          <div className="flex h-24">{heroRender()}</div>

          <div className="px-1">{bookingFormRender()}</div>
        </div>
      </div>
    </>
  );
};

const disabledDate = (current: Dayjs, openingHours: OpeningHour[]) => {
  const workingDays = openingHours?.map((oh) => {
    return oh.translation.en;
  });

  const currentIsWorkDay = workingDays?.includes(
    current.format("dddd").toLocaleLowerCase()
  );

  const currentIsOlderThanToday = current < dayjs().startOf("day");

  return !currentIsWorkDay || currentIsOlderThanToday;
};

export default index;
