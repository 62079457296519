import React from "react";
import { Marker, StaticGoogleMap } from "react-static-google-map";

type Props = {
  latLng: string;
};

const StaticMap = ({ latLng }: Props) => {
  return (
    <StaticGoogleMap
      size="600x600"
      apiKey="AIzaSyD3udM4es0Z5oYo7CPZUnSM9JT8XjioDJ8"
      zoom={17}
    >
      <Marker location={latLng.substring(1, latLng.length - 1)} color="red" />
    </StaticGoogleMap>
  );
};

export default StaticMap;
