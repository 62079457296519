import { useAccessToken } from "@nhost/react";
import { useCan, useList, useNavigation, useShow } from "@refinedev/core";
import Icons from "@src/components/Icons";
import ImageLoader from "@src/components/ImageLoader";
import { LoadingScreen } from "@src/components/LoadingScreen";
import Widget from "@src/components/Widget";
import { Image, Org } from "@src/types";
import { getOrgIdFromAcessToken } from "@src/utils/jwt";
import { getUuidLastPart } from "@src/utils/regex";
import { Divider } from "antd";
import dayjs from "dayjs";
import React from "react";

const List = (props: any) => {
  const { edit } = useNavigation();
  const accessToken = useAccessToken();

  const weekdays = [
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ];

  const {
    queryResult: { data, isLoading },
  } = useShow<Org>({
    resource: "org",
    id: getOrgIdFromAcessToken(accessToken),
    meta: {
      fields: [
        "id",
        "name",
        "description",
        { openingHours: [{ translation: ["id", "en"] }, "from", "to"] },
        {
          adresses: ["description", "latLng", { area: ["en"], city: ["en"] }],
        },
      ],
    },
  });

  const imageList = useList<Image>({
    resource: "image",
    meta: { fields: ["id", "type", "storageId"] },
  }).data?.data;

  const canCreate = useCan({
    resource: "org",
    action: "create",
  }).data?.can;

  // const locationCoordinates = {
  //   lat: parseFloat(tableData?.data[0]?.latlng.split(",")[0]),
  //   lng: parseFloat(tableData?.data[0]?.latlng.split(",")[1]),
  // };

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="flex flex-col md:flex-row h-full w-full gap-2">
      <div className="flex gap-1 h-full flex-col md:w-2/3">
        <Widget className="flex flex-col h-[16rem] md:h-1/3 w-full py-2 px-2 md:px-6">
          <div className="flex relative items-center w-full h-1/2">
            <div className="flex flex-col justify-center capitalize w-2/3">
              <Icons.Edit
                size={18}
                className="text-orange-300 cursor-pointer absolute -top-0 md:-left-4"
                onClick={() => edit("org", data?.data.id)}
              />
              <p className="text-2xl mt-6"> {data?.data.name}</p>
              <p className="font-thin text-sm">
                {data?.data.id && getUuidLastPart(data?.data.id)}
              </p>

              <p className="font-light mt-4">{data?.data.description}</p>
            </div>
            <div className="flex w-1/3">
              <ImageLoader
                className="flex w-20 lg:w-24 h-20 lg:h-24 rounded-lg"
                storageId={
                  imageList?.find((image) => image.type == "avatar")?.storageId
                }
              />
            </div>
          </div>

          <Divider dashed className="m-1" />

          <div className="flex flex-col w-full items-center overflow-scroll">
            {data?.data.adresses?.map((adress, key) => (
              <div
                key={key}
                className="flex justify-between w-full h-full gap-2"
              >
                <div className="flex flex-col justify-center w-2/3 ">
                  <div className="flex font-normal capitalize">
                    <p className="">{adress.city.en}</p>
                    <span className="mx-1"> . </span>
                    <p className="">{adress.area.en}</p>
                  </div>

                  <div className="">
                    <p className="opacity-60">{adress.description}</p>
                  </div>
                </div>

                <div className="flex justify-center items-center w-1/3">
                  {/* <div className="flex w-16 h-16 justify-center items-center  border rounded-full cursor-pointer">
                    <Icons.MapLocation size={32} />
                  </div> */}
                  <p
                    className="underline text-sm opacity-70 text-blue-700 cursor-pointer"
                    onClick={() => alert(`show ${adress.area.en}`)}
                  >
                    show location
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Widget>

        <Widget className="flex flex-col py-4 md:py-2 md:h-2/3 w-full">
          <div className="flex flex-col relative justify-center gap-1  w-full lg:h-2/3 lg:px-4">
            {data?.data.openingHours
              .sort(
                (a, b) =>
                  weekdays.indexOf(a.translation.en) -
                  weekdays.indexOf(b.translation.en)
              )
              .map(({ from, to, translation }, key) => (
                <div key={key} className="flex w-full  px-2">
                  <p className="w-1/2 md:w-1/2  text-md capitalize italic">
                    {translation.en}
                  </p>

                  <p className="w-1/2 flex text-sm md:text-base items-center justify-center gap-2">
                    <Icons.Clock className="opacity-30" />
                    {`${dayjs(from, "hh:mm:ss").format("hh:mm a")} - ${dayjs(
                      to,
                      "hh:mm:ss"
                    ).format("hh:mm a")}`}
                  </p>
                </div>
              ))}
          </div>

          <div className="px-6">
            <Divider dashed />
          </div>

          <div className="flex justify-center h-1/3">
            <p className="opacity-50 ">Upcoming holidays</p>
          </div>
        </Widget>
      </div>

      <Widget className="flex min-h-screen md:min-h-full md:w-1/3">
        <div className="flex flex-col w-full h-full  gap-1">
          {Array.from(Array(5).keys()).map((idx) => (
            <div
              key={idx}
              className="flex h-full w-full  bg-white items-center  justify-center p-1 rounded-lg"
            >
              <ImageLoader
                storageId={
                  imageList
                    ?.filter((img) => img.type == "org")
                    .find((image, imgIdx) => idx == imgIdx)?.storageId
                }
                className="flex w-full h-full opacity-90 rounded-lg object-cover"
              />
            </div>
          ))}
        </div>
      </Widget>
    </div>
    // <div className="flex flex-col w-full min-h-full gap-2  overflow-scroll">
    //   <div className="flex flex-col lg:flex-row w-full lg:h-3/6 gap-2">
    //     <div className="flex flex-col w-full lg:w-1/2 gap-1 ">
    //       <Widget className="flex flex-col relative items-center h-full min-h-[14rem]  md:min-h-[24rem] lg:min-h-full p-2  lg:p-4 ">
    //         <div className="flex relative items-center w-full h-1/2 ">
    //           <div className="flex flex-col justify-center capitalize w-2/3">
    //             <Icons.Edit
    //               size={18}
    //               className="text-orange-200 cursor-pointer absolute top-1 left-"
    //               onClick={() => edit("org", data?.data.id)}
    //             />
    //             <p className="text-2xl mt-6"> {data?.data.name}</p>
    //             <p className="font-thin text-sm">
    //               {data?.data.id && getUuidLastPart(data?.data.id)}
    //             </p>

    //             <p className="font-light mt-4">{data?.data.description}</p>
    //           </div>
    //           <div className="flex w-1/3">
    //             <ImageLoader
    //               className="flex w-20 lg:w-24 h-20 lg:h-24 rounded-lg"
    //               storageId={
    //                 imageList?.find((image) => image.type == "avatar")
    //                   ?.storageId
    //               }
    //             />
    //           </div>
    //         </div>

    //         <Divider dashed />

    //         <div className="flex flex-col w-full h-1/2 items-center ">
    //           {data?.data.adresses?.map((adress, key) => (
    //             <div
    //               key={key}
    //               className="flex justify-between w-full h-full gap-2"
    //             >
    //               <div className="flex flex-col justify-center w-2/3 ">
    //                 <div className="flex font-normal lg:text-xl capitalize">
    //                   <p className="">{adress.city.en}</p>
    //                   <span className="mx-1"> . </span>
    //                   <p className="">{adress.area.en}</p>
    //                 </div>

    //                 <div className="">
    //                   <p className="opacity-60">{adress.description}</p>
    //                 </div>
    //               </div>

    //               <div className="flex justify-center items-center w-1/3">
    //                 {/* <div className="flex w-16 h-16 justify-center items-center  border rounded-full cursor-pointer">
    //                 <Icons.MapLocation size={32} />
    //               </div> */}
    //                 <p
    //                   className="underline text-sm opacity-70 text-blue-700 cursor-pointer"
    //                   onClick={() => alert(`show ${adress.area.en}`)}
    //                 >
    //                   show location
    //                 </p>
    //               </div>
    //             </div>
    //           ))}
    //         </div>
    //       </Widget>
    //     </div>

    //     <div className="flex w-full lg:w-1/2 ">
    //       <Widget className="flex flex-col w-full lg:p-4 justify-around py-6 lg:py-0">
    //         <div className="flex flex-col relative justify-center gap-1  w-full lg:h-2/3 lg:px-6">
    //           {data?.data.openingHours
    //             .sort(
    //               (a, b) =>
    //                 weekdays.indexOf(a.translation.en) -
    //                 weekdays.indexOf(b.translation.en)
    //             )
    //             .map(({ from, to, translation }, key) => (
    //               <div key={key} className="flex w-full  px-2">
    //                 <p className="w-1/3 md:w-1/2  text-md capitalize italic">
    //                   {translation.en}
    //                 </p>

    //                 <p className="w-2/3 md:w-1/2 flex text-sm md:text-base items-center justify-center gap-2">
    //                   <Icons.Clock className="opacity-30" />
    //                   {`${dayjs(from, "hh:mm:ss").format("hh:mm a")} - ${dayjs(
    //                     to,
    //                     "hh:mm:ss"
    //                   ).format("hh:mm a")}`}
    //                 </p>
    //               </div>
    //             ))}
    //         </div>

    //         <Divider className="-py-1" />

    //         <div className="flex justify-center h-1/3">
    //           <p className="opacity-50 ">Upcoming holidays</p>
    //         </div>
    //       </Widget>
    //     </div>
    //   </div>

    //   <div className="flex w-full min-h-[36rem] lg:min-h-max lg:h-3/6 gap-1">
    //     <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 w-full h-full  gap-1">
    //       {Array.from(Array(5).keys()).map((idx) => (
    //         <div
    //           key={idx}
    //           className="flex h-full w-full min-h-[16rem] bg-white items-center  justify-center p-1 rounded-lg"
    //         >
    //           <ImageLoader
    //             storageId={
    //               imageList
    //                 ?.filter((img) => img.type == "org")
    //                 .find((image, imgIdx) => idx == imgIdx)?.storageId
    //             }
    //             className="flex w-full h-full opacity-90 rounded-lg object-cover"
    //           />
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );
};

export default List;
