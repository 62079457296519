import { useLink, useLogin } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import Icons from "@src/components/Icons";
import ImageLoader from "@src/components/ImageLoader";
import Input from "@src/components/input/Input";
import { LoginFormValues, SocialLogin } from "@src/providers/authProvider";
import { darkMode, setHtmlDarkMode } from "@src/utils/theme";
import { Button, Divider } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Controller } from "react-hook-form";

const Login = () => {
  const { mutate: login } = useLogin<LoginFormValues | SocialLogin>();
  const Link = useLink();

  const {
    refineCore: { onFinish, formLoading, queryResult },
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: process.env.DEFAULT_LOGIN_USER,
      password: process.env.DEFAULT_LOGIN_PASS,
    },
  });

  const onSubmit = handleSubmit((data: LoginFormValues) => {
    login(data);
  });

  useEffect(() => {
    setHtmlDarkMode(darkMode());
  }, []);

  return (
    <div className="flex h-screen w-full lg:mt-0 lg:items-center justify-center bg-gray-100">
      <Helmet>
        <title>{`Login`} </title>
      </Helmet>

      <div className="absolute flex justify-center items-center w-full h-full px-2 ">
        <div className="flex flex-col justify-around items-center w-full max-w-sm h-5/6 p-8 rounded-lg shadow-2xl bg-white backdrop-blur">
          <div className="flex h-28 w-28 border-0">
            <ImageLoader
              src="/favicon.ico"
              className="w-full rounded-md"
              objectFit="object-cover"
            />
          </div>

          <form
            className="flex flex-col justify-around items-center gap-4 w-full "
            onSubmit={onSubmit}
          >
            <div className="w-full">
              <Controller
                name={"email"}
                render={({ field: { onChange, value } }) => (
                  <Input
                    className=""
                    onChange={onChange}
                    value={value}
                    errors={errors.name}
                    name="email"
                    autoFocus={true}
                    placeholder="Email"
                    size="large"
                  />
                )}
                control={control}
              />

              <Controller
                name={"password"}
                render={({ field: { onChange, value } }) => (
                  <Input
                    onChange={onChange}
                    className="w-full"
                    value={value}
                    errors={errors.name}
                    label={""}
                    name="password"
                    type="password"
                    placeholder="Password"
                    size="large"
                  />
                )}
                control={control}
              />
            </div>

            <Button
              className=" capitalize border-0"
              htmlType="submit"
              size="large"
              type="primary"
              block
            >
              continue
            </Button>
          </form>

          <Divider>
            <span className="text-xs">OR</span>
          </Divider>

          <div className="flex flex-col w-full gap-2 ">
            {/* <Button
              className="flex items-center gap-4 capitalize"
              size="large"
              block
              onClick={(e) => {
                e.preventDefault();
                login("github");
              }}
            >
              <Icons.Github size={22} />
              continue with Github
            </Button> */}
            <Button
              className="flex items-center gap-4 capitalize"
              size="large"
              block
              onClick={(e) => {
                e.preventDefault();
                login("google");
              }}
            >
              <Icons.Google size={24} />
              continue with Google
            </Button>
            <Button
              className="flex items-center gap-4 capitalize"
              size="large"
              block
              onClick={(e) => {
                e.preventDefault();
                login("facebook");
              }}
            >
              <Icons.Facebook size={22} />
              continue with Facebook
            </Button>
            <div className="self-center mt-10">
              <span className="opacity-50">Not a member? </span>
              <span className=" text-blue-600 dark:text-blue-500 hover:underline">
                <Link to="/signup">Sign up here</Link>
              </span>
            </div>
            {/* <Button
              className="flex items-center gap-4 capitalize"
              size="large"
              block
              onClick={(e) => {
                e.preventDefault();
                login("spotify");
              }}
            >
              <Icons.Spotify size={24} />
              continue with Spotify
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
