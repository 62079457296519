import { joiResolver } from "@hookform/resolvers/joi";
import { FormAction, useCreate, useList, useOne } from "@refinedev/core";
import CustomerAutocomplete from "@src/components/CustomerAutocomplete";
import { LoadingScreen } from "@src/components/LoadingScreen";
import Input from "@src/components/input/Input";
import TagSelect from "@src/components/select/TagSelect";
import {
  selectedSlotAtom,
  serviceIdAtom,
  staffIdAtom,
} from "@src/store/calendar-atoms";
import { Booking, Customer, Service, Staff, Tag } from "@src/types";
import { AuthUserRes } from "@src/types/auth-user";
import { errorMessage, successMessage } from "@src/utils/feedback-message";
import { Button, Divider } from "antd";
import { Dayjs } from "dayjs";
import Joi from "joi";
import { useAtom } from "jotai";
import _ from "lodash";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import Icons from "../../Icons";

// type FormType = "edit" | "create" | "show";
export type MetaData = {
  staffId: string;
  serviceId: string;
  start: Dayjs;
  end: Dayjs;
};

type Props = {
  formType?: FormAction;
  metaData?: MetaData;
  handleClose?: () => void;
};

type FromValuesType = {
  staffId: string;
  serviceId: string;
  customer: Customer;
  start: Dayjs;
  end: Dayjs;
  tags: string[];
};

const schema = Joi.object({
  customer: {
    id: Joi.string().allow("").optional(),
    mobile: Joi.string()
      .pattern(/^\d{11}$/)
      .allow("")
      .optional()
      .messages({
        "string.pattern.base": "invalid mobile number",
      }),
    name: Joi.string().required(),
  },
}).unknown(true);

const Form: FC<Props> = ({ formType, handleClose }) => {
  const [serviceId] = useAtom(serviceIdAtom);
  const [staffId] = useAtom(staffIdAtom);

  // const [customerId, setCustomerId] = useAtom(customerIdAtom);
  const [selectedSlot] = useAtom(selectedSlotAtom);
  const { mutate: createBooking } = useCreate<Booking>();
  const { mutate: createCustomer } = useCreate<Customer>();

  const defaultValues: FromValuesType = {
    staffId: staffId,
    serviceId: serviceId,
    customer: {
      id: "",
      mobile: "",
      name: "",
    },
    start: selectedSlot.start,
    end: selectedSlot.end,
    tags: [],
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    trigger,
    getValues,
  } = useForm({
    defaultValues,
    resolver: joiResolver(schema),
  });

  const { data: staffById, isLoading: staffIsLoading } = useOne<Staff>({
    resource: "staff",
    id: staffId,
    meta: { fields: ["id", "authId"] },
    queryOptions: { enabled: !!watch("staffId") },
  });

  const { data: authUser, isLoading: authUserIsLoading } = useOne<AuthUserRes>({
    resource: "users",
    dataProviderName: "nhostObjects",
    id: staffById?.data.authId,
    meta: { fields: ["id", "displayName"], operation: "user" },
    queryOptions: { enabled: !!staffById?.data.authId },
  });

  const { data: serviceById, isLoading: serviceIsLoading } = useOne<Service>({
    resource: "service",
    id: watch("serviceId"),
    meta: { fields: ["id", "duration", "name", "price"] },
    queryOptions: { enabled: !!watch("serviceId") },
  });

  const tagsList = useList<Tag>({
    resource: "tag",
    meta: { fields: ["id", "name", "price"] },
  }).data?.data;

  const onSubmit = async (data: FromValuesType) => {
    const filteredData = _.omit(data, ["tags", "customer"]);

    const bookingTags = data.tags
      .map((tag) => _.find(tagsList, { name: tag }))
      .map((tag) => tag.id)
      .map((id) => {
        return { tagId: id };
      });

    const customer = new Promise((resolve, reject) => {
      if (data.customer.id) resolve(data.customer.id);
      else {
        const filteredCustomer = _.omit(data.customer, ["id"]);
        filteredCustomer.mobile =
          filteredCustomer.mobile == "" ? null : filteredCustomer.mobile;

        createCustomer(
          {
            resource: "customer",
            values: { ...filteredCustomer },
          },
          {
            onSuccess: (resp) => resolve(resp.data.id),
            onError: () => reject("customer creation failed"),
          }
        );
      }
    });

    await customer.then((customerId) => {
      createBooking(
        {
          resource: "booking",
          meta: { fields: [{ bookingTags: ["tagId"] }] },
          values: {
            ...filteredData,
            customerId,
            // price: serviceById?.data.price,
            bookingTags: {
              data: bookingTags,
            },
          },
        },
        {
          onSuccess: () => {
            successMessage("booked successfully!");

            handleClose();
          },
          onError: (e) => {
            console.log(e);

            errorMessage("booking failed!");
          },
        }
      );
    });
  };

  if (staffIsLoading || authUserIsLoading || serviceIsLoading)
    return <LoadingScreen />;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full h-full py-4 lg:max-w-lg"
    >
      <div className="h-1/5">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 text-xl">
            <p className="capitalize font-semibold">{`${serviceById?.data.name} (${serviceById?.data.duration}min)`}</p>
          </div>
        </div>

        <div className="flex gap-2 h-1/3">
          <div className="flex gap-2">
            <p className="font-semibold text-xl">
              {watch("start")?.format("hh:mm a")}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="opacity-50 ">-</p>
            <p className="font-semibold text-xl">
              {watch("end")?.format("hh:mm a")}
            </p>
          </div>
        </div>

        <div className="flex gap-2">
          <span className="italic opacity-50">by</span>
          <p className="capitalize font-semibold">
            {authUser?.data.displayName}
          </p>
        </div>
      </div>

      <Divider dashed />

      <div className="flex flex-col gap-4">
        <CustomerAutocomplete
          setCustomer={setValue}
          className="w-full max-w-xs"
          size="large"
        />

        <Controller
          name={"customer.mobile"}
          render={({ field: { onChange, value } }) => (
            <Input
              name="mobile"
              label="mobile"
              onChange={onChange}
              value={value}
              className="w-full max-w-xs"
              disabled={!!watch("customer.id")}
              errors={errors.customer?.mobile}
              size="large"
            />
          )}
          control={control}
        />
      </div>

      <Divider dashed className="my-4" />

      {tagsList?.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="w-full max-w-xs">
            <Controller
              name={"tags"}
              render={({ field: { onChange, value } }) => (
                <TagSelect
                  items={tagsList}
                  onChange={onChange}
                  value={value}
                  size="large"
                />
              )}
              control={control}
            />
          </div>
          <Divider dashed className="my-4" />
        </div>
      )}

      <Button
        type="primary"
        className="flex items-center  justify-center gap-4 w-32"
        // disabled={!isValid}
        htmlType="submit"
      >
        <Icons.Save size={16} />
        <p>Save</p>
      </Button>
    </form>
  );
};

export default Form;
