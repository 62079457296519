import { useList, useMany } from "@refinedev/core";
import ImageLoader from "@src/components/ImageLoader";
import { LoadingScreen } from "@src/components/LoadingScreen";
import Statistics from "@src/components/Statistics";
import Widget from "@src/components/Widget";
import { Booking } from "@src/types";
import { AuthUserRes } from "@src/types/auth-user";
import { DailyEvent } from "@src/types/views";
import { enumerateDatesInBetween } from "@src/utils/date";
import { getUserIdentity } from "@src/utils/localstorage";
import { Divider } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import ms from "ms";
import React, { useMemo } from "react";
import { Bar, Line } from "react-chartjs-2";

const List = () => {
  const userProfile = getUserIdentity()?.profile;

  const { data: dailyEvents, isLoading: dailyEventsIsLoading } =
    useList<DailyEvent>({
      resource: "v_events_agg_date",
      queryOptions: {
        refetchInterval: ms(process.env.REFRESH_INTERVAL),
      },
      metaData: { fields: ["count", "date", "authId", "revenew"] },
      pagination: { pageSize: 100 },
    });

  const { data: latestEvents, isLoading: latestEventsIsLoading } =
    useList<Booking>({
      resource: "booking",
      meta: {
        fields: [
          "start",
          { staff: ["id", "color", "authId"] },
          { service: ["name", "duration"] },
        ],
      },
      sorters: [{ field: "createdAt", order: "desc" }],
      pagination: { pageSize: 10 },
      queryOptions: { refetchInterval: ms(process.env.REFRESH_INTERVAL) },
    });

  const authIds = latestEvents?.data
    .map((event) => [event.staff.authId])
    .flat();

  const uniqueAuthIds = [...new Set(authIds)];

  const { data: authProfiles, isLoading: authProfilesIsLoading } =
    useMany<AuthUserRes>({
      resource: "users",
      dataProviderName: "nhostObjects",
      meta: { fields: ["id", "displayName", "avatarUrl"] },
      ids: uniqueAuthIds,
    });

  const userDailyEvents = useMemo<DailyEvent[]>(() => {
    const events = _.cloneDeep(dailyEvents?.data);

    return events?.filter((event) => {
      return userProfile?.role.name == "org.admin"
        ? true
        : event.authId === userProfile?.id;
    });
  }, [dailyEvents?.data]);

  const reducedUserdDailyEvents = useMemo(() => {
    const events = _.cloneDeep(dailyEvents?.data);

    const result = events?.reduce((acc: DailyEvent[], event) => {
      const existingEntry = _.find(acc, { date: event.date });

      if (existingEntry) {
        existingEntry.count += event.count;
      } else acc.push(event);

      return acc;
    }, []);

    return result;
  }, [userDailyEvents]);

  if (dailyEventsIsLoading || latestEventsIsLoading) return <LoadingScreen />;

  return (
    <div className="flex flex-col w-full gap-2 overflow-scroll">
      {reducedUserdDailyEvents && (
        <Statistics events={reducedUserdDailyEvents} />
      )}

      <div className="flex h-full flex-col md:flex-row w-full gap-2">
        <div className="flex flex-col w-full md:w-2/3 gap-2 ">
          <Widget className="flex h-52 md:h-80 gap-4 p-4">
            {reducedUserdDailyEvents && (
              <Bar
                className=""
                data={monthyRevenewChartData(reducedUserdDailyEvents)}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: true,
                      },
                      ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value: string, index, ticks) {
                          return parseInt(value) / 1000 + "k";
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </Widget>

          <Widget className="flex h-52 md:h-80 gap-4 p-4">
            <div className="flex w-full">
              {reducedUserdDailyEvents && (
                <Line
                  className="w-full"
                  data={dailyLoadChartData(reducedUserdDailyEvents)}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        grid: {
                          display: true,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </Widget>
        </div>

        <Widget className="flex w-full md:w-1/3 py-4 px-2 md:px-6 ">
          <div className="flex flex-col w-full justify-start gap-y-1 ">
            {latestEvents?.data.map((event, key) =>
              renderEvent(event, key, authProfiles?.data)
            )}
          </div>
        </Widget>
      </div>
    </div>
  );
};

const renderEvent = (
  event: Booking,
  key: number,
  authProfiles: AuthUserRes[]
) => {
  return (
    <div
      key={key}
      className="flex w-full h-16 items-center px-4 border rounded-lg"
    >
      <div className="w-1/4">
        <ImageLoader
          src={_.find(authProfiles, { id: event.staff?.authId })?.avatarUrl}
          className="w-9 h-9 rounded-full "
          objectFit="object-cover"
        />
      </div>

      <Divider type="vertical" className="py-3" />

      <div className="flex flex-col w-2/4  items-center text-xs">
        <p className="capitalize ">{event.service?.name}</p>
        <p className="capitalize opacity-50">({event.service?.duration}min)</p>
      </div>

      <Divider type="vertical" className="py-3" />

      <div className="flex justify-center w-1/4 italic text-xs">
        <p className="self-center ">{dayjs(event.start).format("MMM DD")}</p>
      </div>
    </div>
  );
};

const eventsToChartDataMapper = (events: DailyEvent[]) => {
  return enumerateDatesInBetween(
    dayjs().startOf("month"),
    dayjs().endOf("month")
  ).map((date) => {
    const filterEvent = events.find(
      (event) => event.date === date.format("YYYYMMDD")
    );

    return filterEvent
      ? {
          date: dayjs(filterEvent.date, "YYYYMMDD").format("MMMDD"),
          count: filterEvent.count,
        }
      : { date: date.format("MMMDD"), count: 0 };
  });
};

const revenewToChartDataMapper = (events: DailyEvent[]) => {
  return enumerateDatesInBetween(
    dayjs().startOf("month"),
    dayjs().endOf("month")
  ).map((date) => {
    const filterEvent = events.find(
      (event) => event.date === date.format("YYYYMMDD")
    );

    return filterEvent
      ? {
          date: dayjs(filterEvent.date, "YYYYMMDD").format("MMMDD"),
          count: filterEvent.revenew,
        }
      : { date: date.format("MMMDD"), count: 0 };
  });
};

const monthyEventsChartData = (events: DailyEvent[]) => {
  const totalEvents = eventsToChartDataMapper(events);

  const data = {
    labels: totalEvents?.map((event) => dayjs(event.date).format("DD")),
    datasets: [
      {
        label: "Monthly Bookings",
        data: totalEvents?.map((event) => event.count),
        borderColor: "rgb(240, 157, 127)",
        backgroundColor: "rgba(240, 157, 127, 0.7)",
        fill: false,

        borderWidth: 1,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  return data;
};

const monthyRevenewChartData = (events: DailyEvent[]) => {
  const totalEvents = revenewToChartDataMapper(events);

  const data = {
    labels: totalEvents?.map((event) => dayjs(event.date).format("DD")),
    datasets: [
      {
        label: "Revenew",
        data: totalEvents?.map((event) => event.count),
        borderColor: "rgb(240, 157, 127)",
        backgroundColor: "rgba(240, 157, 127, 0.7)",
        fill: false,

        borderWidth: 1,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  return data;
};

const dailyLoadChartData = (events: DailyEvent[]) => {
  const totalEvents = eventsToChartDataMapper(events);

  const data = {
    labels: totalEvents?.map((event) => dayjs(event.date).format("DD")),
    datasets: [
      {
        label: "Booking",
        data: totalEvents?.map((event) => event.count),
        borderColor: "rgb(240, 157, 127)",
        backgroundColor: "rgba(240, 157, 127, 0.7)",
        fill: true,
        pointRadius: 2,
        borderWidth: 1,
        lineTension: 0.4,
      },
    ],
  };

  return data;
};

export default List;
