import { Tag } from "@src/types";
import { handleDropdownVisibleChange } from "@src/utils/dropdown";
import { formatPrice } from "@src/utils/formatter";
import { Tag as AntdTag, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import React from "react";
import Label from "../Label";

type Props = {
  onChange?: (...event: any[]) => void;
  value?: string[];
  size?: SizeType;
  items: Tag[];
};

const TagSelect = ({ onChange, value, size = "middle", items }: Props) => {
  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <AntdTag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 6 }}
      >
        <span className="px-2 uppercase">{label} </span>
      </AntdTag>
    );
  };

  const handleChange = (value: string[]) => {
    onChange(value);
  };

  return (
    <div className="flex flex-col">
      <Label label="tags" />
      <Select
        placeholder="Additional Services"
        mode="multiple"
        showArrow
        tagRender={tagRender}
        size={size}
        className="w-full"
        onChange={handleChange}
        optionLabelProp="label"
        value={value}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        showSearch={false}
      >
        {items?.map((tag, idx) => (
          <Select.Option key={idx} value={tag.name} label={tag.name}>
            <div className="grid grid-cols-2 gap-6 px-4">
              <p className="uppercase font-semibold">{tag.name}</p>
              <div className="flex justify-end items-center">
                <span className="">{formatPrice(tag.price)}</span>
                <span className="opacity-50 text-xs font-light mt-1 uppercase">
                  iqd
                </span>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default TagSelect;
