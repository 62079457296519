import { OpeningHours } from "@src/types/calendar";
import dayjs, { Dayjs } from "dayjs";

//TODO remove if not used
export const enumerateDatesInBetween = (
  startDate: Dayjs,
  endDate: Dayjs
): Dayjs[] => {
  if (startDate.isSame(endDate)) return [startDate];

  let current = startDate.clone();
  let range: Dayjs[] = [];

  while (current.isSameOrBefore(endDate, "day")) {
    range.push(current.clone());
    current = current.add(1, "day");
  }

  return range;
};

export const enumerateHoursInBetween = (
  date: Dayjs,
  openingHours: OpeningHours
) => {
  if (
    (!openingHours.open && !openingHours.close) ||
    openingHours.close < openingHours.open
  )
    return [];

  let startTime = date.set({ hours: openingHours.open });
  const endTime = date.set({ hours: openingHours.close });
  const diff = endTime.diff(startTime, "h");

  const resultArray: Dayjs[] = [];
  resultArray.push(startTime);

  Array(diff)
    .fill(1)
    .forEach((value, index) => {
      resultArray.push(startTime.add(index + 1, "h"));
    });

  return resultArray;
};

export const setDateOnTimeString = (time: Dayjs, targetDate: Dayjs) => {
  const updatedDateTime = dayjs(time, "HH:mm")
    .set("year", targetDate.year())
    .set("month", targetDate.month())
    .set("date", targetDate.date());

  return updatedDateTime;
};
