import { useMany } from "@refinedev/core";
import { Staff } from "@src/types";
import { AuthUserRes } from "@src/types/auth-user";
import { SizeType } from "antd/es/config-provider/SizeContext";
import classNames from "classnames";
import _ from "lodash";
import React, { Dispatch, FC, SetStateAction, forwardRef } from "react";
import ImageLoader from "../ImageLoader";
import Select, { Option } from "./Select";

type Props = {
  className?: string;
  onChange: Dispatch<SetStateAction<string>>;
  value?: string;
  disabled?: boolean;
  defaultListItemIsShown?: boolean;
  defaultListItemDisabled?: boolean;
  size?: SizeType;
  orgId?: string;
  items: Staff[];
};

const StaffSelect: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      value,
      defaultListItemDisabled = true,
      defaultListItemIsShown = false,
      className,
      size,
      orgId,
      items = [],
    },
    ref
  ) => {
    // const staffList = useList<Staff>({
    //   resource: "staff",
    //   meta: { fields: ["id", "authId"] },
    //   pagination: { pageSize: 100 },
    //   filters: orgId && [{ field: "orgId", operator: "eq", value: orgId }],
    // }).data?.data;

    const authUserIds = items?.map((staff) => staff.authId);

    const authUserList = useMany<AuthUserRes>({
      resource: "users",
      dataProviderName: "nhostObjects",
      ids: authUserIds,
      queryOptions: { enabled: !!authUserIds },
      meta: { fields: ["id", "displayName", "avatarUrl"] },
    }).data?.data;

    if (!items) return;

    return (
      <Select
        onChange={(value) => {
          onChange(value);
        }}
        value={value}
        className={classNames("text-center", className)}
        size={size}
      >
        <>
          {items?.map((staff, idx) => {
            const authData = _.find(authUserList, { id: staff.authId });
            const fullName = `${_.capitalize(authData?.displayName)}`;

            return (
              <Option key={idx} value={staff.id} label={fullName}>
                <div className="flex w-full h-full gap-6 items-center px-4">
                  <div className="flex w-10 h-10">
                    <ImageLoader
                      className="h-10 w-10 rounded-full"
                      src={authData?.avatarUrl}
                      objectFit="object-cover"
                    />
                  </div>
                  <p className="w-auto">{fullName}</p>
                </div>
              </Option>
            );
          })}
        </>
      </Select>
    );
  }
);

export default StaffSelect;
