import { Radio } from "antd";
import React from "react";
import Label from "./Label";

type Props = {
  onChange: (...event: any[]) => void;
  value: "male" | "female";
};

const options = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
];

const GenderRadio = ({ onChange, value }: Props) => {
  return (
    <div>
      <Label label="gender" />
      <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
        optionType="button"
      />
    </div>
  );
};

export default GenderRadio;
